import { FC, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import HeaderAction from './HeaderAction';

import ImageUploadInput from '~/components/image-upload-input';
import WrapperTextField from '~/components/WrapperTextField';
import { useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { MemberSiteFormEnum } from '~/enum/pages/my-shop';
import { env } from '~/env';
import { GetMyShopDocument, useCheckExistDomainLazyQuery, useUpdateMyShopMutation } from '~/graphql/member/types';
import { steps } from '~/pages/my-shop/create-member-site';
import { SHOP_TYPE } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

const schema = yup.object({
  siteName: yup.string().max(100).required(),
  metaDescription: yup.string().max(1000),
  ogpFile: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  logoFile: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  faviconFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  ogp: yup.string().nullable(),
  logo: yup.string().nullable(),
  favicon: yup.string().nullable(),
  domainName: yup
    .string()
    .max(100)
    .matches(/^[a-zA-Z0-9_-]*$/, { message: 'form_validation.shop_url_validation', excludeEmptyString: true })
    .required(),
});

const useStyles = makeStyles<{ isCreate?: boolean }>()((theme, { isCreate }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root': {
      marginBottom: '24px',
      '& > .MuiCardHeader-root': {
        padding: '16px',
        minHeight: '52px',
        borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
        [theme.breakpoints.up('sm')]: {
          padding: '0px 16px',
        },
        '.MuiCardHeader-action': {
          alignSelf: 'center',
        },
      },
      '.MuiCardHeader-title': {
        display: isCreate ? '' : 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'unset',
        },
      },
    },
    '.MuiCardContent-root': {
      gap: '16px',
      padding: '16px 16px 0',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
      '.cardContent': {
        '&__left': {
          gap: '16px',
          width: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&-wrapperSiteImage': {
            '&--siteImage': {
              aspectRatio: '408 / 235',
              '.MuiFormControl-root': {
                height: '100%',
              },
            },
          },
          '&-wrapperLogo': {
            gap: '16px',
            display: 'flex',
            '&--item': {
              flex: 1,
              '& > .MuiBox-root': {
                aspectRatio: '1 / 1',
                '.MuiFormControl-root': {
                  height: '100%',
                },
              },
            },
          },
        },
        '&__right': {
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          '&-wrapperInput': {
            '.MuiTextField-root': {
              '.MuiInputAdornment-root': {
                '.MuiTypography-root': {
                  fontSize: '16px',
                },
                '.MuiTypography-caption': {
                  fontSize: '12px',
                  [theme.breakpoints.up('sm')]: {
                    fontSize: '18px',
                  },
                },
              },
            },
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
    '.MuiCardHeader-action': {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  cardHeader: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

export interface FormBasicSiteSettingValues extends yup.InferType<typeof schema> {}

interface BasicSiteSettingProps {
  isCreate?: boolean;
}

const BasicSiteSetting: FC<BasicSiteSettingProps> = ({ isCreate }) => {
  const { editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handleNextStep } = useMyShopFormWrapper();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEditing = editingAt === MemberSiteFormEnum.BASIC;

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles({ isCreate });
  const { enqueueSnackbar } = useSnackbar();

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [checkExistDomain] = useCheckExistDomainLazyQuery();

  const defaultValues = useMemo(
    () => ({
      ogp: formData?.siteSetting?.ogp!,
      logo: formData?.siteSetting?.logo!,
      logoFile: formData?.siteSetting?.logoFile!,
      favicon: formData?.siteSetting?.favicon!,
      ogpFile: formData?.siteSetting?.ogpFile!,
      faviconFile: formData?.siteSetting?.faviconFile!,
      siteName: formData?.siteSetting?.siteName!,
      domainName: formData?.siteSetting?.domainName!,
      baseCurrency: formData?.paymentMethod?.baseCurrency!,
      metaDescription: formData?.siteSetting?.metaDescription!,
    }),
    [formData]
  );

  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormBasicSiteSettingValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleChangeToEdit = () => {
    handleEditingAt(MemberSiteFormEnum.BASIC);
  };
  const handleCancel = () => {
    if (isCreate) {
      navigate(AppRouteEnum.MemberSite);
    } else {
      reset(defaultValues as FormBasicSiteSettingValues);
      handleEditingAt(undefined);
    }
  };

  const onSubmit = async (data: FormBasicSiteSettingValues) => {
    setIsSubmitting(true);
    if (formData?.siteSetting?.domainName?.trim() !== data.domainName.trim()) {
      const isExistDomain = await checkExistDomain({
        fetchPolicy: 'cache-and-network',
        variables: {
          shopType: SHOP_TYPE.MEMBER,
          domain: data.domainName.trim(),
        },
      });
      if (isExistDomain?.data?.checkExistDomain) {
        setError('domainName', { message: t('my_shop.message.errors.domain_is_existing') });
        setIsSubmitting(false);
        return;
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      siteSetting: {
        ...prevState.siteSetting,
        ...data,
      },
    }));
    if (isCreate) {
      handleNextStep(steps(t, isSubmitting).length);
      setIsSubmitting(false);
      return;
    }
    try {
      await updateMyShop({
        variables: {
          input: {
            uuid: id!,
            siteSetting: {
              title: data.siteName,
              description: data.metaDescription,
            },
            domain: {
              name: data.domainName.trim(),
            },
            ogpFile: data.ogpFile?.[0],
            logoFile: data.logoFile?.[0],
            faviconFile: data.faviconFile?.[0],
          },
        },
      });
      handleEditingAt(undefined);
      setIsSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={t('member_site.basic_settings')}
          action={!isCreate && <HeaderAction />}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent__left">
            <Box className="cardContent__left-wrapperSiteImage">
              <Typography variant="h5">{t('my_shop.site_image')}</Typography>
              <Box className="cardContent__left-wrapperSiteImage--siteImage">
                <ImageUploadInput<FormBasicSiteSettingValues>
                  required
                  type="image"
                  height="100%"
                  name="ogpFile"
                  control={control}
                  label={t('image')}
                  disabled={!(isCreate || isEditing) || isSubmitting}
                  defaultImageUrlName="ogp"
                  defaultImagePath={'/default/ogp-member-site.png'}
                  error={!!errors.ogpFile?.message}
                  helperText={t(errors.ogpFile?.message as any, {
                    size: 10,
                  })}
                />
              </Box>
            </Box>
            <Box className="cardContent__left-wrapperLogo">
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('logo')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="logoFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="logo"
                    error={!!errors.logoFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.logoFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('favicon')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="faviconFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="favicon"
                    error={!!errors.faviconFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.faviconFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="cardContent__right">
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="siteName"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('member_site_name')}
                render={(field) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('member_site_name')}
                    error={!!errors.siteName?.message}
                    helperText={getErrorText(errors.siteName?.message, t)}
                    required
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="domainName"
                control={control}
                label={t('my_shop.site_setting.member_site_url')}
                readOnlyBodyStyle={{ color: '#1976d2 !important' }}
                isEditing={isCreate || isEditing}
                formatValue={(field) =>
                  env.REACT_APP_CLIENT_URL + AppRouteEnum.BuyerMemberSite.replace(/:shopName/g, field.value as string)
                }
                render={(field) => (
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    error={!!errors.domainName?.message}
                    helperText={getErrorText(errors.domainName?.message, t)}
                    label={t('my_shop.site_setting.member_site_url') + ' (tokenstudio.gu.net/member/)'}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="metaDescription"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('my_shop.site_setting.membersite_description')}
                readOnlyBodyStyle={{
                  height: '120px',
                }}
                render={(field) => (
                  <TextField
                    rows={4}
                    fullWidth
                    multiline
                    margin="normal"
                    disabled={isSubmitting}
                    placeholder={t('my_shop.shop_description')}
                    label={t('my_shop.site_setting.membersite_description')}
                    error={!!errors.metaDescription?.message}
                    helperText={getErrorText(errors.metaDescription?.message, t)}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting || isSubmitting}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && !isDirty) || isSubmitting}
                endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {t(isCreate ? 'next' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default BasicSiteSetting;
