import React, { useMemo } from 'react';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import NavigatorMenu from '~/components/navigator-menu';
import { NavigatorItemConfig } from '~/components/navigator-menu/navigator-item';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from '~/constants/layout';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import { ReactComponent as CollectionIcon } from '~/icons/images/collection-icon.svg';
import { ReactComponent as HomeIcon } from '~/icons/images/home-icon.svg';
import { ReactComponent as MembersiteIcon } from '~/icons/images/member-site-icon.svg';
import { ReactComponent as SettingIcon } from '~/icons/images/settings-icon.svg';
import { ReactComponent as ShopIcon } from '~/icons/images/shop-icon.svg';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
  },
  logo: {
    '& img': {
      width: 130,
      [theme.breakpoints.up('sm')]: {
        width: 170,
      },
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}

const { HOME, COLLECTIONS, SHOPS, MEMBER_SITES, SETTING, USER_MANUAL } = SCREEN_PERMISSION;

const SideBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, openDrawer, onCloseDrawer } = props;
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const config = useMemo<NavigatorItemConfig[]>(
    () => [
      {
        path: AppRouteEnum.Home,
        label: t('home_page.home'),
        icon: <HomeIcon />,
        roles: HOME.VIEW,
      },
      {
        path: AppRouteEnum.Collection,
        label: t('collections'),
        icon: <CollectionIcon />,
        roles: COLLECTIONS.VIEW,
      },
      {
        path: AppRouteEnum.MyShop,
        label: t('my_shop.shops'),
        icon: <ShopIcon />,
        roles: SHOPS.VIEW,
      },
      {
        path: AppRouteEnum.MemberSite,
        label: t('member_site.member_site'),
        icon: <MembersiteIcon />,
        roles: MEMBER_SITES.VIEW,
      },
      {
        path: AppRouteEnum.Setting,
        label: t('setting'),
        icon: <SettingIcon />,
        roles: SETTING.VIEW,
      },
      ...(!!env.REACT_APP_USER_MANUAL_URL
        ? [
            {
              roles: USER_MANUAL.VIEW,
              label: t('user_manual'),
              icon: <ArticleOutlinedIcon sx={{ fill: 'rgb(28, 27, 31)' }} />,
              url: env.REACT_APP_USER_MANUAL_URL,
            },
          ]
        : []),
    ],
    [t]
  );

  return (
    <Drawer
      open={isTemporarySideBar ? openDrawer : true}
      variant={isTemporarySideBar ? 'temporary' : 'persistent'}
      onClose={onCloseDrawer}
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      data-testid="side-bar"
      anchor={isTemporarySideBar ? 'right' : 'left'}
    >
      <NavigatorMenu config={config} isTemporarySideBar={isTemporarySideBar} onCloseDrawer={onCloseDrawer} />
    </Drawer>
  );
};

export default SideBar;
