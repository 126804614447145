import { FC } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { colors } from '~/constants/colors';
import { CURRENCY_ICONS } from '~/constants/common';
import { Currency, GetPlansQuery, OrganizationUserRole } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface IPlanCard {
  plan: GetPlansQuery['getPlans'][number];
  onSelectPlan: (plan: GetPlansQuery['getPlans'][number]) => void;
}

const useStyles = makeStyles()(() => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '.MuiTypography-h4': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const PlanCard: FC<IPlanCard> = ({ plan, onSelectPlan }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { plan: currentPlan, selectedOrganization } = useAccount();

  const isSelected = currentPlan?.uuid === plan.uuid;
  const isOwner = selectedOrganization.role === OrganizationUserRole.Owner;

  const infos = [
    {
      title: t('plans.number_of_users'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_users_desc',
          value: { amount: plan.numberOfAdminUsers?.toLocaleString() },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_users',
          value: { price: '$' + plan.additionalUserFee?.toLocaleString() },
        },
      ],
    },
    {
      title: t('plans.number_of_shops'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_shops_desc',
          value: { amount: plan.numberOfShops?.toLocaleString() },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_shops',
          value: { price: '$' + plan.additionalShopFee?.toLocaleString() },
        },
      ],
    },
    {
      title: t('plans.number_of_member_sites'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_member_sites_desc',
          value: { amount: plan.numberOfMemberSites?.toLocaleString() },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_member_sites',
          value: { price: '$' + plan.additionalSiteFee?.toLocaleString() },
        },
      ],
    },
    {
      title: t('plans.number_of_members_per_site'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_members_per_site_desc',
          value: {
            amount: (plan.numberOfMemberPerSite || 0) * (plan.numberOfMembersPerLicense || 0),
            licenses: plan.numberOfMemberPerSite || 0,
          },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_members_per_site',
          value: { price: '$' + plan.additionalMemberFee?.toLocaleString(), amount: plan.numberOfMembersPerLicense },
        },
      ],
    },
  ];

  return (
    <>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box>
            <Typography variant="h6">{plan.planName}</Typography>
            <Typography variant="h4">
              {plan.monthlyFee === 0
                ? t('free')
                : `${CURRENCY_ICONS[Currency.Usd]}${plan.monthlyFee?.toLocaleString()}`}
            </Typography>
          </Box>
          <Stack gap="16px" height={470} overflow="auto">
            {infos.map((info, idx) => (
              <Stack key={idx} gap="8px">
                <Typography variant="subtitle1" fontWeight={500}>
                  {info.title}
                </Typography>
                {info.desc.map((item, idx) => (
                  <Stack key={idx} alignItems="flex-start" direction="row" gap="4px" color={item.color}>
                    {item.icon}
                    <Typography whiteSpace="pre-wrap">
                      <Trans i18nKey={item.key} values={item.value} />
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </CardContent>
        <CardActions>
          <Button fullWidth variant="contained" disabled={isSelected || !isOwner} onClick={() => onSelectPlan(plan)}>
            {t(isSelected ? 'selected' : 'plans.select_this_plan')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default PlanCard;
