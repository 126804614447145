import { FC, useCallback, useState } from 'react';

import { WalletConnectorDialog, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import AddIcon from '@mui/icons-material/Add';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Box from '@mui/material/Box';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import EditPriceDialog from '../../EditPriceDialog';
import { ICollectionInfo, useShopCollectionDetail } from '../ShopCollectionDetail';

import AddMyShopCollectionNFT from './AddMyShopCollectionNFT';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import WrapperWithFab, { IFabItem } from '~/components/WrapperWithFab';
import {
  GetMyShopDocument,
  ListMyShopCollectionTokensDocument,
  MyShopCollectionsTokensQueryKey,
  useSetMyShopCollectionTokenMutation,
  useSetMyShopCollectionTokensMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import ExistingNFTList, {
  MintedNFTInDataGrid,
} from '~/pages/my-shop/shop-detail/components/CollectionDetail/Minted/ExistingNFTList';
import { STATUS } from '~/types/my-shop';

interface WrapperExistingNFTListProps {
  collectionInfo: ICollectionInfo | undefined;
  onOpenRemoveCollectionDialog: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapperSalesNFT: {
    width: '100%',
  },
}));

const WrapperExistingNFTList: FC<WrapperExistingNFTListProps> = ({ collectionInfo, onOpenRemoveCollectionDialog }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isLocked } = useAccount();
  const { account } = useWalletAccount();
  const { data: myShopCollection } = useShopCollectionDetail();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const [isOpenAddNFT, setIsOpenAddNFT] = useState(false);
  const [openWalletDialog, setOpenWalletDialog] = useState(false);
  const [selectedToken, setSelectedToken] = useState<MintedNFTInDataGrid>();
  const [loadingTokensList, setLoadingTokensList] = useState<boolean>(true);
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState<boolean>(false);
  const [selectedNFTIds, setSelectedNFTIds] = useState<GridRowSelectionModel>([]);

  const [setMyShopCollectionToken] = useSetMyShopCollectionTokenMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionTokensDocument],
  });

  const [setMyShopCollectionTokens] = useSetMyShopCollectionTokensMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionTokensDocument],
  });

  const openAddNFTDialog = useCallback(() => {
    setIsOpenAddNFT(true);
  }, []);

  const closeAddNFTDialog = useCallback(() => {
    setIsOpenAddNFT(false);
  }, []);

  const handleOpenAddNFT = () => {
    if (isLocked) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }

    if (account) {
      openAddNFTDialog();
    } else if (!isOpenAddNFT) {
      setOpenWalletDialog(true);
    }
  };

  const handleCloseWalletDialog = () => {
    setOpenWalletDialog(false);
  };

  const selectNFTIds = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedNFTIds(rowSelectionModel);
  };

  const handleOpenEditPriceDialog = () => {
    setOpenEditPriceDialog(true);
  };
  const handleCloseEditPriceDialog = () => {
    setOpenEditPriceDialog(false);
  };

  const handleLoadingTokensList = (isLoading: boolean) => {
    setLoadingTokensList(isLoading);
  };

  const onSavePriceNFT = useCallback(
    async (newPrice: number, withSell?: boolean) => {
      try {
        setLoadingTokensList(true);

        if (!!selectedToken) {
          await setMyShopCollectionToken({
            variables: {
              input: {
                price: newPrice,
                myShopCollectionUuid: myShopCollection?.uuid!,
                status: withSell ? STATUS.NOW_ON_SALE : undefined,
                tokenId: selectedToken?.[MyShopCollectionsTokensQueryKey.TokenId]!,
              },
            },
          });
          setSelectedToken((prevState) => {
            if (!prevState) {
              return prevState;
            }
            return {
              ...prevState,
              price: newPrice,
              [MyShopCollectionsTokensQueryKey.Price]: newPrice,
              ...(withSell
                ? { status: STATUS.NOW_ON_SALE, [MyShopCollectionsTokensQueryKey.Status]: STATUS.NOW_ON_SALE }
                : {}),
            };
          });
        } else if (selectedNFTIds.length > 0) {
          const result = await setMyShopCollectionTokens({
            variables: {
              input: {
                price: newPrice,
                tokenIds: selectedNFTIds as string[],
                myShopCollectionUuid: myShopCollection?.uuid!,
                status: withSell ? STATUS.NOW_ON_SALE : undefined,
              },
            },
          });
          if (result?.data?.setMyShopCollectionTokens) {
            setSelectedNFTIds([]);
          }
        }
        handleCloseEditPriceDialog();
        showSuccess('my_shop.message.update_successful');
      } catch (e: any) {
        setLoadingTokensList(false);
        showError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedToken, selectedNFTIds, myShopCollection.uuid]
  );

  const menus = [
    {
      title: t('add_nft'),
      onClick: handleOpenAddNFT,
    },
    {
      title: t('my_shop.remove_from_shop'),
      onClick: onOpenRemoveCollectionDialog,
    },
  ];

  const fabsList: IFabItem[] = [
    {
      color: 'primary',
      content: <AddIcon />,
      onClick: handleOpenAddNFT,
    },
    {
      color: 'warning',
      show: selectedNFTIds.length > 0,
      content: <CurrencyExchangeOutlinedIcon />,
      onClick: () => {
        setSelectedToken(undefined);
        handleOpenEditPriceDialog();
      },
    },
  ];

  return (
    <Box className={classes.wrapperSalesNFT}>
      <WrapperWithFab items={fabsList} onClick={handleOpenAddNFT}>
        <CustomCardTable
          headerAction={<HeaderAction menus={menus} />}
          cardTitle={collectionInfo?.myShopCollectionName || collectionInfo?.name || '-'}
          cardContent={
            <ExistingNFTList
              selectedToken={selectedToken}
              selectedNFTIds={selectedNFTIds}
              collectionInfo={collectionInfo}
              loadingTokensList={loadingTokensList}
              selectNFTIds={selectNFTIds}
              onOpenAddNFT={handleOpenAddNFT}
              setSelectedToken={setSelectedToken}
              onLoadingTokensList={handleLoadingTokensList}
              onOpenEditPriceDialog={handleOpenEditPriceDialog}
            />
          }
        />
      </WrapperWithFab>
      {account && isOpenAddNFT && <AddMyShopCollectionNFT open={isOpenAddNFT} onClose={closeAddNFTDialog} />}
      <WalletConnectorDialog open={openWalletDialog} onClose={handleCloseWalletDialog} />
      <EditPriceDialog
        rowData={selectedToken}
        open={openEditPriceDialog}
        onSavePrice={onSavePriceNFT}
        onClose={handleCloseEditPriceDialog}
      />
    </Box>
  );
};

export default WrapperExistingNFTList;
