import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
  GridCellCheckboxRenderer,
  GridColDef,
  GridHeaderCheckbox,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import NoResultsOverlay from '~/components/custom-list-toolbar-filter';
import EditNameDialog, { IEditNFTNameField } from '~/components/dialog/edit-nft-name-dialog';
import MintOnPurchaseNFTDialog from '~/components/dialog/mint-on-puchase-nft-dialog';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { API_MEDIA, CHIP_COLOR, CURRENCY_ICONS, ITEMS_PER_PAGE } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { VIEW_MODE } from '~/enum/common';
import { MyShopCollectionImageList } from '~/enum/tableFields';
import {
  useSetMyShopCollectionImageMutation,
  useUpdateMyShopCollectionMutation,
  MemberMyShopCollectionImagesQueryKey,
  useListMyShopCollectionImagesQuery,
  ListMyShopCollectionImagesDocument,
  GetMyShopDocument,
  Currency,
  QueryOperator,
  QueryMethod,
  useListMyShopCollectionImageIdsQuery,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useShopCollectionDetail } from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import {
  collectionStatus,
  renderSelectEditInputCell,
} from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { NFT_STATUS, STATUS } from '~/types/my-shop';
import { priceWithSymbol, verifyOrderKey, verifySortKey } from '~/utils/common';

interface ImageNFTListProps {
  selectedItem?: ImageInDataGrid;
  selectedItemIds: GridRowSelectionModel;
  onOpenEditPriceDialog: () => void;
  selectItemIds: (rowSelectionModel: GridRowSelectionModel) => void;
  setSelectedItem: Dispatch<SetStateAction<ImageInDataGrid | undefined>>;
}

export interface ImageInDataGrid {
  id: string;
  url: string;
  name: string;
  order?: number;
  price?: number;
  status?: string;
  createAt?: string;
  currentMint: number;
  networkSymbol: string;
  originalName?: string;
  maxMint: number | null;
  estimatedUsdPrice?: number;
  collectionImageUuid?: string;

  [MemberMyShopCollectionImagesQueryKey.Name]?: string;
  [MemberMyShopCollectionImagesQueryKey.CreatedAt]?: string;
  [MemberMyShopCollectionImagesQueryKey.CurrentMint]: number;
  [MemberMyShopCollectionImagesQueryKey.MaxMint]: number | null;
  [MemberMyShopCollectionImagesQueryKey.CollectionUuid]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageUuid]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]?: number;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]?: string;
}

const useStyles = makeStyles()(() => ({
  imageListBox: {
    width: '100%',
    '.image-grid': {
      marginBottom: '24px',
    },
    '.MuiTypography-subtitle1': {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      marginBottom: '16px',
    },
    '.MuiTypography-caption': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '16px',
      display: 'inline-block',
      letterSpacing: '0.17px',
    },
    '.sold-out': {
      opacity: '0.5',
    },
    '.MuiSelect-standard': {
      backgroundColor: 'transparent!important',
    },
  },
  noPriceSet: {
    ':not(.estimate)': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ImageNFTList: FC<ImageNFTListProps> = ({
  selectedItem,
  selectedItemIds,
  selectItemIds,
  setSelectedItem,
  onOpenEditPriceDialog,
}) => {
  const { data: myShop, exchangeRate } = useShopDetail();
  const { data: myShopCollection } = useShopCollectionDetail();

  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);

  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { show } = usePaymentMethodRequired();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const [queryShopColImages, setQueryShopColImages] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(
      MemberMyShopCollectionImagesQueryKey,
      myShopCollection?.sortBy,
      MemberMyShopCollectionImagesQueryKey.CreatedAt
    ),
    orderBy: verifyOrderKey(myShopCollection?.orderBy),
    where: {
      myShopCollectionUuid: myShopCollection?.uuid ?? '',
    },
  });

  const { data: listMyShopCollectionImageIdsRes, loading: isLoadingIds } = useListMyShopCollectionImageIdsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !myShopCollection?.uuid || typeof exchangeRate !== 'number',
    variables: {
      where: {
        onlyAvailable: true,
        myShopCollectionUuid: myShopCollection?.uuid ?? '',
        condition: QueryMethod.Or,
        fields: [
          {
            value: [],
            operator: QueryOperator.Empty,
            key: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
          },
          {
            value: [STATUS.NOW_ON_SALE, STATUS.SUSPENSION],
            operator: QueryOperator.Anyof,
            key: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
          },
        ],
      },
    },
  });
  const { data: dataShopCollectionImagesData, loading: isLoading } = useListMyShopCollectionImagesQuery({
    variables: queryShopColImages,
    fetchPolicy: 'cache-and-network',
    skip: !myShopCollection?.uuid || typeof exchangeRate !== 'number',
  });

  const listMyShopCollectionImageIds = listMyShopCollectionImageIdsRes?.listMyShopCollectionImages.items || [];

  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const [updateMyShopCollectionImage] = useSetMyShopCollectionImageMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionImagesDocument],
  });

  const updateQueryShopColImages = (newValue: any) =>
    setQueryShopColImages((value: any) => ({ ...value, ...newValue }));

  const { items, pagination } = useMemo(() => {
    const items = dataShopCollectionImagesData?.listMyShopCollectionImages?.items || [];
    const pagination: ListTablePagination = dataShopCollectionImagesData?.listMyShopCollectionImages?.pagination || {};

    const _items: ImageInDataGrid[] = items.map((item) => {
      return {
        id: item.uuid,
        order: item.order,
        price: item.price,
        maxMint: item.maxMint,
        originalName: item.name,
        createAt: item.createdAt,
        currentMint: item.currentMint,
        collectionImageUuid: item.uuid,
        url: API_MEDIA + (item.image || ''),
        name: item.myShopCollectionImageName || item.name,
        estimatedUsdPrice: !!item.price ? item.price * exchangeRate! : undefined,
        status:
          (item.maxMint || 0) <= (item.currentMint || 0)
            ? STATUS.SOLD_OUT
            : !item.price && item.price !== 0
            ? STATUS.SUSPENSION
            : item?.status || STATUS.SUSPENSION,

        [MemberMyShopCollectionImagesQueryKey.Name]: item.name,
        [MemberMyShopCollectionImagesQueryKey.CurrentMint]: item.currentMint,
        [MemberMyShopCollectionImagesQueryKey.MaxMint]: item.maxMint,
        [MemberMyShopCollectionImagesQueryKey.CreatedAt]: item.createdAt,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]: item.myShopCollectionImageName || item.name,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]: item.myShopCollectionImageNameJa || item.name,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]: item.price,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]:
          (item.maxMint || 0) <= (item.currentMint || 0)
            ? STATUS.SOLD_OUT
            : !item.price
            ? STATUS.SUSPENSION
            : item?.status || STATUS.SUSPENSION,
        [MemberMyShopCollectionImagesQueryKey.CollectionUuid]: item.collectionUuid,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageUuid]: item.myShopCollectionImageUuid,
      } as ImageInDataGrid;
    });
    return { items: _items, pagination };
  }, [exchangeRate, dataShopCollectionImagesData]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Handle Edit Collection Name Dialog
  const onOpenEditImageCaption = () => {
    setAnchorEl(null);
    setOpenEditNFTNameDialog(true);
  };

  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const handleClickMoreMenu = useCallback(
    (row: ImageInDataGrid): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        if (row.status !== STATUS.SOLD_OUT) {
          setAnchorEl(event.currentTarget);
          setSelectedItem(row);
        } else {
          showErrorByKey('my_shop.message.already_sold_out');
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenEditPriceDialog = useCallback(
    (row?: ImageInDataGrid) => {
      if (row) {
        if (row.status !== STATUS.SOLD_OUT) {
          setSelectedItem(row);
          onOpenEditPriceDialog();
        } else {
          showErrorByKey('my_shop.message.already_sold_out');
        }
      } else if (selectedItem) {
        onOpenEditPriceDialog();
      }
      setAnchorEl(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem, t]
  );

  // Handle image detail dialog
  const onOpenImageDetailDialog = () => {
    setAnchorEl(null);
    setIsOpenNFTDialog(true);
  };
  const onCloseImageDetailDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const handleClickImage = (row: ImageInDataGrid | undefined) => {
    setSelectedItem(row);
    onOpenImageDetailDialog();
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    try {
      await updateMyShopCollectionImage({
        variables: {
          input: {
            name: data.name,
            nameJa: data.nameJa,
            myShopCollectionUuid: myShopCollection?.uuid || '',
            collectionImageUuid: selectedItem?.collectionImageUuid || '',
          },
        },
      });
      setSelectedItem((prevState) => {
        if (prevState) {
          prevState[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName] = data.name;
          prevState[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa] = data.nameJa;
        }
        return prevState;
      });
      showSuccess('toast_message.update_caption_successfully');
      setOpenEditNFTNameDialog(false);
    } catch (err: any) {
      showError(err);
    }
  };

  const handleUpdateStatus = useCallback(
    async (image: ImageInDataGrid | undefined) => {
      try {
        if (!image) return;
        const isForSale = image.status === STATUS.NOW_ON_SALE;
        const soldOut = (Number(image.maxMint) || 0) <= (image.currentMint || 0);
        if (soldOut && !isForSale) {
          showErrorByKey('my_shop.message.already_sold_out');
          return;
        }
        if (typeof image.price !== 'number') {
          showErrorByKey('my_shop.message.need_set_price');
          return;
        }
        if (!isForSale && image.price !== 0 && !!myShop.publish) {
          const notRegistered = await show({
            description: 'payment_method_required.nft_has_price',
          });
          if (notRegistered) {
            return;
          }
        }
        await updateMyShopCollectionImage({
          variables: {
            input: {
              collectionImageUuid: image.collectionImageUuid || '',
              status: isForSale ? STATUS.SUSPENSION : STATUS.NOW_ON_SALE,
              myShopCollectionUuid: myShopCollection?.uuid || '',
            },
          },
        });
        setSelectedItem((prevState) => {
          if (prevState) {
            prevState.status = isForSale ? STATUS.SUSPENSION : STATUS.NOW_ON_SALE;
          }
          return prevState;
        });
        showSuccess('my_shop.message.update_successful');
      } catch (e: any) {
        showError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myShopCollection?.uuid]
  );

  const handleStatus = async () => {
    await handleUpdateStatus(selectedItem);
    setAnchorEl(null);
  };

  const checkStatusNft = useCallback(
    (status: string) => {
      if (status === NFT_STATUS.ON_SALE) {
        return <Chip label={t('on_sale')} color="success" />;
      } else {
        return <Chip label={t('unsold')} color="error" />;
      }
    },
    [t]
  );

  const handleProcessRowUpdate = async (newRow: ImageInDataGrid, oldRow: ImageInDataGrid) => {
    try {
      if (oldRow.status === STATUS.SOLD_OUT) {
        showErrorByKey('my_shop.message.already_sold_out');
        return oldRow;
      }
      if (newRow.order === oldRow.order) {
        return newRow;
      }
      await updateMyShopCollectionImage({
        variables: {
          input: {
            collectionImageUuid: newRow.collectionImageUuid ?? '',
            myShopCollectionUuid: myShopCollection?.uuid ?? '',
            order: newRow.order ? parseInt(newRow.order.toString(), 10) : newRow.order,
          },
        },
      });
    } catch (err: any) {}
    return newRow;
  };

  const handleSelectAll = useCallback(async () => {
    const selectedItemIdsLength = selectedItemIds.length;
    const isSelectAll = listMyShopCollectionImageIds.length === selectedItemIdsLength;
    if (isSelectAll || selectedItemIdsLength > 0) {
      selectItemIds([]);
    } else {
      const listIds = listMyShopCollectionImageIds.map((item) => item.uuid) || [];
      selectItemIds(listIds as GridRowSelectionModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemIds, listMyShopCollectionImageIds]);

  const imageListColumns: GridColDef<ImageInDataGrid>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 50,
        align: 'center',
        sortable: false,
        resizable: false,
        filterable: false,
        field: '__check__',
        disableExport: true,
        disableReorder: true,
        disableColumnMenu: true,
        type: 'checkboxSelection',
        renderHeader: (params) => <GridHeaderCheckbox {...(params as any)} onChange={handleSelectAll} />,
        renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
      },
      {
        width: 84,
        sortable: false,
        resizable: false,
        field: MyShopCollectionImageList.URL,
        headerName: t('image'),
        renderCell: ({ row }) => <SquareImage src={row.url} onClick={() => handleClickImage(row)} />,
      },
      {
        width: 100,
        editable: true,
        sortable: false,
        resizable: false,
        field: MyShopCollectionImageList.ORDER,
        headerName: t('my_shop.order_index'),
        renderEditCell: renderSelectEditInputCell,
      },
      {
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 125,
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
        renderCell: ({ row }) => {
          const status: STATUS = (row.status as STATUS) || STATUS.SUSPENSION;
          return (
            <>
              {row.status === STATUS.SOLD_OUT ? (
                <Chip
                  variant="filled"
                  color={CHIP_COLOR[status]}
                  label={`${collectionStatus(t)[status]?.title}` || '-'}
                />
              ) : (
                <Select
                  variant="standard"
                  disableUnderline
                  fullWidth
                  value={row.status === STATUS.NOW_ON_SALE ? NFT_STATUS.ON_SALE : NFT_STATUS.UNSOLD}
                  onChange={() => handleUpdateStatus(row)}
                >
                  {Object.values(NFT_STATUS).map((status) => (
                    <MenuItem key={status} value={status}>
                      {checkStatusNft(status)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          );
        },
      },
      {
        headerName: t('price'),
        width: JSON.parse(columnsSize).price || 130,
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice,
        renderCell({ row }) {
          const baseCurrency = myShop?.paymentMethod?.baseCurrency;
          const basePrice = row.price;
          return (
            <>
              {!!basePrice ? (
                `${baseCurrency ? CURRENCY_ICONS[baseCurrency] : ''} ${basePrice.toLocaleString('en-EN')}`
              ) : basePrice === 0 ? (
                <Chip variant="filled" color="success" label={t('free')} />
              ) : (
                <Typography
                  className={classes.noPriceSet}
                  color="red"
                  onClick={() => {
                    handleOpenEditPriceDialog(row);
                  }}
                >
                  {t('no_price_set')}
                </Typography>
              )}
            </>
          );
        },
      },
      ...(!!myShop.siteSetting?.showPriceUsd
        ? [
            {
              sortable: false,
              field: 'estimatedUsdPrice',
              headerName: t('my_shop.est_usd_price'),
              width: JSON.parse(columnsSize).estimatedUsdPrice || 160,
              renderCell({ row }: { row: ImageInDataGrid }) {
                const basePrice = row.price;
                const estimatedUsdPrice = row.estimatedUsdPrice;
                return (
                  <>
                    {!!basePrice ? (
                      `≈ ${priceWithSymbol(estimatedUsdPrice, Currency.Usd, 2)}`
                    ) : basePrice === 0 ? (
                      <Chip variant="filled" color="success" label={t('free')} />
                    ) : (
                      <Typography className={cx(classes.noPriceSet, 'estimate')} color="red">
                        {t('no_price_set')}
                      </Typography>
                    )}
                  </>
                );
              },
            },
          ]
        : []),
      {
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName,
        headerName: t('my_shop.image_caption_en'),
        width: JSON.parse(columnsSize).caption || 150,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa,
        headerName: t('my_shop.image_caption_ja'),
        width: JSON.parse(columnsSize).caption || 150,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.Name,
        headerName: t('my_shop.image_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        headerName: t('collection_screen.max_mint'),
        width: JSON.parse(columnsSize).maxMint || 100,
        field: MemberMyShopCollectionImagesQueryKey.MaxMint,
        valueFormatter: ({ value }) => Number(value).toLocaleString(),
      },
      {
        width: JSON.parse(columnsSize).currentMint || 120,
        headerName: t('collection_screen.current_mint_count'),
        field: MemberMyShopCollectionImagesQueryKey.CurrentMint,
        valueFormatter: ({ value }) => Number(value).toLocaleString(),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <IconButton aria-hidden="false" onClick={handleClickMoreMenu(row)}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    classes.noPriceSet,
    myShop?.paymentMethod?.baseCurrency,
    checkStatusNft,
    handleSelectAll,
    handleUpdateStatus,
    handleClickMoreMenu,
    handleOpenEditPriceDialog,
  ]);

  const handleUpdateSort = async (value: any) => {
    const sortBy = value.sortBy;
    const orderBy = value.orderBy;
    updateQueryShopColImages({ sortBy, orderBy });
    if (sortBy !== myShopCollection?.sortBy || orderBy !== myShopCollection?.orderBy) {
      await updateMyShopCollection({
        variables: {
          input: { uuid: myShopCollection?.uuid || '', sortBy, orderBy },
        },
      });
    }
  };

  return (
    <Box className={classes.imageListBox}>
      <ListTable
        isMenu
        noBorder
        rows={items}
        editMode="row"
        checkboxSelection
        onlyMode={VIEW_MODE.LIST}
        disableRowSelectionOnClick
        columns={imageListColumns}
        paginationData={pagination}
        keepNonExistentRowsSelected
        isLoading={isLoading || isLoadingIds}
        searchLabel={t('my_shop.image_name')}
        tableName={'shop_collection_premint'}
        search={queryShopColImages.searchText}
        noRowsMessage={t('my_shop.message.no_image')}
        isRowSelectable={({ row }) => row.status !== STATUS.SOLD_OUT}
        rowSelectionModel={selectedItemIds as GridInputRowSelectionModel}
        getRowClassName={(params) => (params.row.status === STATUS.SOLD_OUT ? 'sold-out' : '')}
        sort={{
          sortBy: queryShopColImages.sortBy,
          orderBy: queryShopColImages.orderBy,
        }}
        onSort={handleUpdateSort}
        onRowUpdate={handleProcessRowUpdate}
        onPagination={updateQueryShopColImages}
        onRowSelectionModelChange={selectItemIds}
        slots={{ noResultsOverlay: NoResultsOverlay }}
        onSearch={(v) => updateQueryShopColImages({ page: 1, searchText: v || '' })}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={onOpenImageDetailDialog}>{t('show_details')}</MenuItem>
        <MenuItem onClick={onOpenEditImageCaption}>{t('my_shop.edit_image_caption')}</MenuItem>
        <MenuItem onClick={() => handleOpenEditPriceDialog()}>{t('edit_price')}</MenuItem>
        <MenuItem onClick={handleStatus}>
          {selectedItem?.status !== STATUS.NOW_ON_SALE ? t('on_sale') : t('unsold')}
        </MenuItem>
      </Menu>
      <MintOnPurchaseNFTDialog
        open={isOpenNFTDialog}
        nftInfo={selectedItem!}
        onClose={onCloseImageDetailDialog}
        onEditNFTName={handleEditNFTName}
        onOpenPriceDialog={() => handleOpenEditPriceDialog()}
        actions={[
          <Box key="1">
            <Button
              variant="outlined"
              sx={{ float: 'right' }}
              color={selectedItem?.status === STATUS.SUSPENSION ? 'primary' : 'error'}
              disabled={(!selectedItem?.price && selectedItem?.price !== 0) || selectedItem?.status === STATUS.SOLD_OUT}
              onClick={handleStatus}
            >
              {selectedItem?.status === STATUS.SOLD_OUT
                ? t('my_shop.sold_out')
                : selectedItem?.status === STATUS.SUSPENSION
                ? t('on_sale')
                : t('unsold')}
            </Button>
          </Box>,
        ]}
      />
      <EditNameDialog
        open={openEditNFTNameDialog}
        title={t('my_shop.edit_image_caption')}
        defaultName={selectedItem?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]!}
        defaultNameJa={selectedItem?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]!}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
    </Box>
  );
};

export default ImageNFTList;
