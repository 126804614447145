import { FC, ReactNode } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Fade } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab, { FabTypeMap } from '@mui/material/Fab';
import { makeStyles } from 'tss-react/mui';

export interface IFabItem {
  show?: boolean;
  content: ReactNode;
  isLoading?: boolean;
  color?: FabTypeMap['props']['color'];
  variant?: FabTypeMap['props']['variant'];
  onClick: () => void;
}

interface WrapperWithFabProps {
  hidden?: boolean;
  items?: IFabItem[];
  disabled?: boolean;
  children: ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '60px',
  },
  wrapperFab: {
    gap: '8px',
    right: '16px',
    bottom: '16px',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
}));

const WrapperWithFab: FC<WrapperWithFabProps> = ({ items, hidden, isLoading, children, disabled, onClick }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      {children}
      <Box className={classes.wrapperFab}>
        {!hidden &&
          (!!items?.length ? (
            items.map((item, idx) => (
              <Fade in={typeof item.show === 'undefined' ? true : item.show} key={idx}>
                <Fab color={item.color} disabled={disabled} variant={item.variant} onClick={item.onClick}>
                  {item.content}
                </Fab>
              </Fade>
            ))
          ) : (
            <Fab color="primary" onClick={onClick} disabled={disabled}>
              {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <AddIcon />}
            </Fab>
          ))}
      </Box>
    </Box>
  );
};

export default WrapperWithFab;
