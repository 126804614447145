import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import LoaderCenter from '~/components/loader-center';
import { MemberSiteFormEnum, ShopFormEnum } from '~/enum/pages/my-shop';
import {
  Currency,
  ExchangeCurrencyQuery,
  GetMyShopDocument,
  GetMyShopQuery,
  GetPublishShopDocument,
  ListMyShopCollectionImagesDocument,
  ListMyShopCollectionTokensDocument,
  useCancelPublishShopMutation,
  useExchangeCurrencyQuery,
  useGetMyShopQuery,
  useGetPublishShopQuery,
  usePublishShopMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { SHOP_TYPE } from '~/types/my-shop';
import { notFound } from '~/utils/not-found';

export type MyShopResponse = GetMyShopQuery[keyof GetMyShopQuery];

export interface ShopDetailContextValue {
  loading: boolean;
  publishDate?: Date;
  isMemberSite: boolean;
  exchangeRate?: number;
  data: GetMyShopQuery['getMyShop'];
  editingAt: ShopFormEnum | MemberSiteFormEnum | undefined;
  refetchExchangeRate: () => Promise<ApolloQueryResult<ExchangeCurrencyQuery>>;
  handleEditingAt: (tab: ShopFormEnum | MemberSiteFormEnum | undefined) => void;
}

const ShopDetailContext = createContext<ShopDetailContextValue>({} as any);
export const useShopDetail = () => useContext(ShopDetailContext);

const useStyles = makeStyles()(() => ({
  wrapperAlert: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '.MuiAlert-root': {
      color: '#00000099',
      backgroundColor: 'white',
      '.MuiAlert-action': {
        padding: 0,
      },
    },
  },
}));

const ShopDetailWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLocked } = useAccount();
  const { showErrorByKey } = useNotify();

  const [editingAt, setEditingAt] = useState<ShopFormEnum | MemberSiteFormEnum | undefined>();

  const { data: myShopDataRes, loading: loadingMyShop } = useGetMyShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
    onError: (err) => {
      notFound(Object.values(err)?.[0]?.[0]?.extensions?.code, navigate);
    },
  });
  const { data: publishShopRes } = useGetPublishShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
  });
  const { data: exchangeRateRes, refetch: refetchExchangeRate } = useExchangeCurrencyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        from: Currency.Jpy,
        to: Currency.Usd,
      },
    },
  });

  const handleEditingAt = (tab: ShopFormEnum | MemberSiteFormEnum | undefined) => {
    if (isLocked && !!tab) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }
    setEditingAt(tab);
  };

  const myShop = myShopDataRes?.getMyShop;
  const exchangeRate = exchangeRateRes?.exchangeCurrency;
  const publishShopData = publishShopRes?.getPublishShop;

  return (
    <ShopDetailContext.Provider
      value={{
        editingAt,
        exchangeRate,
        data: myShop!,
        loading: loadingMyShop,
        publishDate: publishShopData?.updatedAt,
        isMemberSite: myShop?.shopType === SHOP_TYPE.MEMBER,
        handleEditingAt,
        refetchExchangeRate,
      }}
    >
      <Wrapper>
        {loadingMyShop ? (
          <Box width="100%" height="100%">
            <LoaderCenter />
          </Box>
        ) : (
          <Outlet />
        )}
      </Wrapper>
    </ShopDetailContext.Provider>
  );
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { showError, showSuccess } = useNotify();
  const { data, editingAt, publishDate, isMemberSite } = useShopDetail();

  const [isPublishing, setIsPublishing] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [publishShop] = usePublishShopMutation({
    refetchQueries: [GetMyShopDocument, GetPublishShopDocument],
  });
  const [cancelPublishShop] = useCancelPublishShopMutation({
    refetchQueries: [
      GetMyShopDocument,
      GetPublishShopDocument,
      ListMyShopCollectionTokensDocument,
      ListMyShopCollectionImagesDocument,
    ],
  });

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };
  const handleCloseCancelDialog = async () => {
    setOpenCancelDialog(false);
  };

  const handlePublish = async () => {
    setIsPublishing(true);
    try {
      await publishShop({ variables: { myShopUuid: data?.uuid ?? '' } });
      setIsPublishing(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleCancel = async () => {
    try {
      await cancelPublishShop({ variables: { myShopUuid: data?.uuid || '' } });
      showSuccess('toast_message.canceled_successfully');
    } catch (err: any) {
      showError(err);
    }
  };

  const isEditShop = /edit-(shop|member-site)$/.test(location.pathname);

  if (isEditShop && !!children) {
    return <>{children}</>;
  }

  const publishBar = data?.hasUpdate && (
    <Box className={classes.wrapperAlert}>
      <Alert
        icon={false}
        variant="filled"
        action={
          <>
            {!!publishDate && (
              <Button
                color="error"
                variant="text"
                disabled={!!editingAt || isPublishing}
                onClick={handleOpenCancelDialog}
              >
                {t('cancel')}
              </Button>
            )}
            <Button
              variant="text"
              disabled={!!editingAt || isPublishing}
              endIcon={isPublishing && <CircularProgress size={20} color="inherit" />}
              onClick={handlePublish}
            >
              {t('publish')}
            </Button>
          </>
        }
      >
        {t(isMemberSite ? 'toast_message.there_is_an_update_member_site' : 'toast_message.there_is_an_update_shop')}
      </Alert>
    </Box>
  );

  return (
    <UserLayout aboveContent={publishBar}>
      {children}
      <ConfirmationDialog
        open={openCancelDialog}
        confirmTitle={t('confirm')}
        title={t('cancel_publish_title')}
        content={t(isMemberSite ? 'cancel_publish_member_site_desc' : 'cancel_publish_shop_desc')}
        onConfirm={handleCancel}
        onClose={handleCloseCancelDialog}
      />
    </UserLayout>
  );
};

export default ShopDetailWrapper;
