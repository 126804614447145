import { FC, useCallback, useState } from 'react';

import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Box from '@mui/material/Box';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import EditPriceDialog from '../../EditPriceDialog';
import { ICollectionInfo, useShopCollectionDetail } from '../ShopCollectionDetail';

import ImageNFTList, { ImageInDataGrid } from './ImageNFTList';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import WrapperWithFab, { IFabItem } from '~/components/WrapperWithFab';
import {
  GetMyShopDocument,
  ListMyShopCollectionImagesDocument,
  MemberMyShopCollectionImagesQueryKey,
  useSetMyShopCollectionImageMutation,
  useSetMyShopCollectionImagesMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { STATUS } from '~/types/my-shop';

interface WrapperImageNFTListProps {
  collectionInfo: ICollectionInfo | undefined;
  onOpenRemoveCollectionDialog: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapperSalesNFT: {
    width: '100%',
  },
}));

const WrapperImageNFTList: FC<WrapperImageNFTListProps> = ({ collectionInfo, onOpenRemoveCollectionDialog }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError, showSuccess } = useNotify();
  const { data: myShopCollection } = useShopCollectionDetail();

  const [selectedItem, setSelectedItem] = useState<ImageInDataGrid>();
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState<boolean>(false);
  const [selectedItemIds, setSelectedItemIds] = useState<GridRowSelectionModel>([]);

  const [updateMyShopCollectionImage] = useSetMyShopCollectionImageMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionImagesDocument],
  });

  const [updateMyShopCollectionImages] = useSetMyShopCollectionImagesMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionImagesDocument],
  });

  const handleOpenEditPriceDialog = () => {
    setOpenEditPriceDialog(true);
  };
  const handleCloseEditPriceDialog = () => {
    setOpenEditPriceDialog(false);
  };

  const selectItemIds = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedItemIds(rowSelectionModel);
  };

  const onSavePrice = useCallback(
    async (newPrice: number, withSell?: boolean) => {
      try {
        if (!!selectedItem) {
          await updateMyShopCollectionImage({
            variables: {
              input: {
                price: newPrice,
                status: withSell ? STATUS.NOW_ON_SALE : undefined,
                myShopCollectionUuid: myShopCollection?.uuid || '',
                collectionImageUuid: selectedItem?.collectionImageUuid || '',
              },
            },
          });
          setSelectedItem((prevState) => {
            if (!prevState) {
              return prevState;
            }
            return {
              ...prevState,
              price: newPrice,
              [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]: newPrice,
              ...(withSell
                ? {
                    status: STATUS.NOW_ON_SALE,
                    [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]: STATUS.NOW_ON_SALE,
                  }
                : {}),
            };
          });
        } else if (selectedItemIds.length > 0) {
          const result = await updateMyShopCollectionImages({
            variables: {
              input: {
                price: newPrice,
                status: withSell ? STATUS.NOW_ON_SALE : undefined,
                collectionImageUuids: selectedItemIds as string[],
                myShopCollectionUuid: myShopCollection?.uuid || '',
              },
            },
          });
          if (result?.data?.setMyShopCollectionImages) {
            setSelectedItemIds([]);
          }
        }
        handleCloseEditPriceDialog();
        showSuccess('my_shop.message.update_successful');
      } catch (e: any) {
        showError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem, selectedItemIds, myShopCollection?.uuid]
  );

  const menus = [
    {
      title: t('my_shop.remove_from_shop'),
      onClick: onOpenRemoveCollectionDialog,
    },
  ];

  const fabsList: IFabItem[] = [
    {
      color: 'warning',
      show: selectedItemIds.length > 0,
      content: <CurrencyExchangeOutlinedIcon />,
      onClick: () => {
        setSelectedItem(undefined);
        handleOpenEditPriceDialog();
      },
    },
  ];

  return (
    <Box className={classes.wrapperSalesNFT}>
      <WrapperWithFab items={fabsList}>
        <CustomCardTable
          headerAction={<HeaderAction menus={menus} />}
          cardContent={
            <ImageNFTList
              selectedItem={selectedItem}
              selectedItemIds={selectedItemIds}
              selectItemIds={selectItemIds}
              setSelectedItem={setSelectedItem}
              onOpenEditPriceDialog={handleOpenEditPriceDialog}
            />
          }
          cardTitle={collectionInfo?.myShopCollectionName || collectionInfo?.name || '-'}
        />
      </WrapperWithFab>
      <EditPriceDialog
        rowData={selectedItem}
        open={openEditPriceDialog}
        onSavePrice={onSavePrice}
        onClose={handleCloseEditPriceDialog}
      />
    </Box>
  );
};

export default WrapperImageNFTList;
