import { InvoiceType, MethodsContractEnum } from '~/enum/common';
import { env } from '~/env';
import { Currency } from '~/graphql/member/types';
import { MEMBER_SITE_NFT_STATUS, STATUS, SalesMethodEnum } from '~/types/my-shop';

export const JOC_POINT_PRICE = 0.2;

export const MAX_PAYLOAD_SIZE = 0.1 * 1000 * 1000; // 0.1MB

export const METHODS_CONTRACT = {
  '0xd204c45e': MethodsContractEnum.Mint,
  '0x42842e0e': MethodsContractEnum.Transfer,
};

export const STATUS_CODE = {
  NOT_FOUND: '404',
  UNPREDICTABLE_GAS_LIMIT: 'UNPREDICTABLE_GAS_LIMIT',
};

export const MAX_ORDER = 999999999999999999;
export const MAX_NUMBER = 1000000000000000;
export const ROOT_WALLET = '0x0000000000000000000000000000000000000000';
export const WALLET_CONNECT_PROJECT_ID = '7f4dd594173e28221fe7c065e16c00e6';

export const MAX_FILE_SIZE = 10 * 1000 * 1000;
// 10mb converted from bytes

export const MIN_DEPOSIT = 2;

export const API_MEDIA = env.REACT_APP_API_MEDIA + '/';

export const MAX_LENGTH = 255;

export const SALES_METHOD = {
  ADVANCED: [SalesMethodEnum.ADVANCED_ONLY, SalesMethodEnum.NFT_LIST_AND_ADVANCED],
  NFT_LIST: [SalesMethodEnum.NFT_LIST_ONLY, SalesMethodEnum.NFT_LIST_AND_ADVANCED],
};

export const CARD_IMAGE = {
  jcb: '/logos/jcb.svg',
  visa: '/logos/visa.svg',
  amex: '/logos/amex.svg',
  diners_club: '/logos/diners.svg',
  mastercard: '/logos/mc.svg',
  discover: '/logos/discover.svg',
};

export const DROPPABLE_IDS = {
  SIDEBAR: 'SIDEBAR',
  WRAPPER_EDIT_PAGE: 'WRAPPER_EDIT_PAGE',
  COLLECTION_SECTIONS: 'COLLECTION_SECTIONS',
};

export const DATE_TIME_FORMAT = 'YYYY/MM/DD';

export const DROPPABLE_TYPES = {
  SIDEBAR: 'SIDEBAR',
  COLLECTION: 'COLLECTION',
  COLLECTION_SECTIONS: 'COLLECTION_SECTIONS',
};

export const CURRENCY_ICONS: { [key: string]: string } = {
  [Currency.Jpy]: '¥',
  [Currency.Usd]: '$',
};

export const CHIP_COLOR: {
  [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  [STATUS.SOLD_OUT]: 'error',
  [STATUS.SUSPENSION]: 'warning',
  [STATUS.NOW_ON_SALE]: 'success',
  [MEMBER_SITE_NFT_STATUS.VALID]: 'success',
  [MEMBER_SITE_NFT_STATUS.INVALID]: 'error',
  [MEMBER_SITE_NFT_STATUS.NOT_CONFIRM]: 'error',
  [MEMBER_SITE_NFT_STATUS.UNRESTRICTED]: 'info',
  [MEMBER_SITE_NFT_STATUS.NOT_REGISTERED]: 'error',
};

export const ITEMS_PER_PAGE = {
  LIST: 10,
  GALLERY: 10,
  BLOCKSCOUT: 50,
};

export const limitPrice = {
  [Currency.Jpy]: {
    min: 1,
    max: 9999999,
  },
  [Currency.Usd]: {
    min: 1,
    max: 9999999,
  },
};

export const INVOICE_ITEM_NAME = {
  [InvoiceType.PurchasePlan]: 'plan',
  [InvoiceType.RevenueShare]: 'plans.revenue_share',
  [InvoiceType.PurchaseShopLicense]: 'license.shop',
  [InvoiceType.PurchaseUserLicense]: 'license.user',
  [InvoiceType.PurchasePoint]: 'settings.points.joc_points',
  [InvoiceType.PurchaseMemberSiteLicense]: 'license.member_site',
  [InvoiceType.PurchaseMemberPerSiteLicense]: 'license.member_per_member_site',
};
