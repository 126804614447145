import React, { useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { OrganizationUserRole } from '~/graphql/member/types';
import { StyledComponentProps } from '~/types/material-ui';

export type NavigatorItemConfig =
  | {
      url?: string;
      path?: string;
      label: string;
      icon?: JSX.Element;
      roles?: OrganizationUserRole[];
      children?: NavigatorItemConfig[];
    }
  | 'divider';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    height: 48,
    gap: '12px',
    borderRadius: 4,
    alignItems: 'center',
    '.MuiTypography-root': {
      fontWeight: 'unset',
    },
  },
  listItemIcon: {
    minWidth: 24,
    justifyContent: 'center',
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
  selectedItem: {
    backgroundColor: '#E8F0F9!important',
  },
  selectedIcon: {
    filter: 'invert(37%) sepia(46%) saturate(1522%) hue-rotate(178deg) brightness(99%) contrast(94%)',
  },
  listItemLabel: {
    color: '#1C1B1F',
    lineHeight: '24px',
  },
  selectedLabel: {
    color: '#0367DF',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  item: NavigatorItemConfig;
}

const NavigatorItem: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { item } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (item === 'divider') {
      return;
    }
    if (item.path) {
      navigate(item.path);
    } else if (item.url) {
      const newTab = window.open(item.url, '_blank');

      if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        window.location.href = item.url;
      }
    } else if (item.children) {
      setOpen((o) => !o);
    }
  }, [item, navigate]);

  if (item === 'divider') {
    return <Divider className={classes.divider} />;
  }
  const isSelected =
    item.path?.length === 1
      ? location.pathname === AppRouteEnum.Home
      : new RegExp(`^${item.path}`, 'g').test(location.pathname);

  return (
    <>
      <ListItemButton
        selected={isSelected}
        className={`${classes.listItem} ${isSelected ? classes.selectedItem : ''}`}
        onClick={handleClick}
      >
        {!!item.icon && (
          <ListItemIcon className={`${classes.listItemIcon} ${isSelected ? classes.selectedIcon : ''}`}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText
          className={`${isSelected ? classes.selectedLabel : classes.listItemLabel}`}
          primary={item.label}
        />
        {!!item.children && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {!!item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {item.children.map((i, index) => (
              <NavigatorItem item={i} key={index} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavigatorItem;
