import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { red } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomDialog from '~/components/dialog/custom-dialog';
import LicenseStatementDialog from '~/components/dialog/license-statement-dialog';
import LoaderCenter from '~/components/loader-center';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { colors } from '~/constants/colors';
import { MAX_NUMBER } from '~/constants/common';
import { GetInfoUsageDocument, LicenseType, useGetInfoUsageQuery } from '~/graphql/member/types';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles()(() => ({
  card: {
    '.MuiCardContent-root': {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
      '.MuiGrid-item': {
        height: '155px',
        padding: '16px 24px',
        boxShadow: `0 0 0 0.5px ${colors.divider}`,
        '.quantity': {
          color: colors.primary,
          ':not(.full)': {
            color: colors.text.secondary,
            span: {
              color: colors.text.primary,
            },
          },
        },
        '.MuiButton-root.addMore': {
          padding: 0,
          minHeight: 'initial',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
}));

const schema = yup.object({
  quantity: yup.string().maxNumber(MAX_NUMBER).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const SummaryInformation = () => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { plan: currentPlan } = useAccount();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedType, setSelectedType] = useState<LicenseType>();
  const [openLicenseStatement, setOpenLicenseStatement] = useState(false);

  const [addable] = useCheckPermissions([SCREEN_PERMISSION.SETTING.PLAN_MANAGEMENT.ADD]);

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const quantity = useWatch({ control, name: 'quantity' });

  const {
    data: infoUsageRes,
    error: infoUsageError,
    loading: loadingInfoUsage,
  } = useGetInfoUsageQuery({
    fetchPolicy: 'cache-and-network',
  });
  const isLoading = loadingInfoUsage;

  const buyMoreDescArgs = !quantity
    ? {
        count: 1,
        total: currentPlan?.numberOfMembersPerLicense || 0,
      }
    : {
        count: Number(quantity),
        total: Number(quantity) * (currentPlan?.numberOfMembersPerLicense || 0),
      };

  const planExpired =
    (infoUsageError?.graphQLErrors?.[0].extensions.response as Record<'error', string>)?.error === 'pl_404_1';

  const infoUsage = {
    [LicenseType.AdditionalUserFee]: {
      buyMoreTitle: 'buy_more_users',
      type: LicenseType.AdditionalUserFee,
      title: t('settings.total_of_users'),
      current: infoUsageRes?.getInfoUsage.numberUser,
      limit: infoUsageRes?.getInfoUsage.userLicenses,
    },
    [LicenseType.AdditionalShopFee]: {
      buyMoreTitle: 'buy_more_shops',
      type: LicenseType.AdditionalShopFee,
      title: t('settings.total_of_shops'),
      current: infoUsageRes?.getInfoUsage.numberShop,
      limit: infoUsageRes?.getInfoUsage.shopLicenses,
    },
    [LicenseType.AdditionalMemberSiteFee]: {
      buyMoreTitle: 'buy_more_member_sites',
      type: LicenseType.AdditionalMemberSiteFee,
      title: t('settings.total_of_member_sites'),
      current: infoUsageRes?.getInfoUsage.numberMemberSite,
      limit: infoUsageRes?.getInfoUsage.memberSiteLicenses,
    },
    [LicenseType.AdditionalMemberFee]: {
      type: LicenseType.AdditionalMemberFee,
      limit: infoUsageRes?.getInfoUsage.maxMembersPerSite,
      title: t('settings.total_of_members_per_member_site'),
      buyMoreTitle: 'buy_more_member_licenses_per_member_site',
      current: infoUsageRes?.getInfoUsage.numberOfMemberPerSite,
    },
  };

  // Handle Select Type
  const selectType = (type: LicenseType) => {
    setSelectedType(type);
  };
  const clearSelectedType = () => {
    setSelectedType(undefined);
  };

  // Handle Open Dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearSelectedType();
    reset();
  };

  // Handle Open License Statement
  const handleOpenLicenseStatement = () => {
    setOpenLicenseStatement(true);
  };
  const handleCloseLicenseStatement = async () => {
    setOpenLicenseStatement(false);
  };

  const clickBuyMore = (type: LicenseType) => () => {
    selectType(type);
    handleOpenDialog();
  };

  const handlePurchaseSuccessful = () => {
    handleCloseDialog();
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent sx={{ padding: 0, paddingBottom: 0 }}>
          {isLoading ? (
            <Box height={310}>
              <LoaderCenter />
            </Box>
          ) : (
            <Grid container>
              <Grid xs={12} item>
                <Stack flex={1} gap={1} height="100%" justifyContent="center">
                  <Typography variant="h6">{t('settings.plan_management.current_plan')}</Typography>
                  <Typography variant="h4" color={colors.primary}>
                    {currentPlan?.planName || '-'}
                  </Typography>
                  <Typography variant="body2">{`${t('settings.plan_management.start_date')}: ${moment(
                    currentPlan?.startAt || ''
                  ).format(t('date_time_format'))}`}</Typography>
                </Stack>
              </Grid>
              <Grid container>
                {planExpired ? (
                  <Grid xs={12} item sx={{ backgroundColor: red[50] }}>
                    <Stack gap={1} alignItems="center" justifyContent="center" height="100%">
                      <ReportOutlinedIcon sx={{ color: red[900] }} fontSize="large" />
                      <Typography variant="h6" textAlign="center" color={red[900]}>
                        {t('settings.plan_management.plan_expired')}
                      </Typography>
                    </Stack>
                  </Grid>
                ) : (
                  Object.values(infoUsage).map((info) => (
                    <Grid key={info.type} xs={6} sm={3} item>
                      <Stack gap={1} height="100%" justifyContent="center" alignItems="flex-start">
                        <Tooltip
                          title={info.title}
                          placement="bottom-start"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <Typography
                            noWrap
                            width="100%"
                            fontWeight={500}
                            variant="subtitle1"
                            color={colors.text.secondary}
                          >
                            {info.title}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="h4"
                          className={cx('quantity', { full: (info.current || 0) >= (info.limit || 0) })}
                        >
                          {info.current?.toLocaleString()}
                          <span>{` / ${info.limit?.toLocaleString()}`}</span>
                        </Typography>
                        {addable && (
                          <Button
                            variant="text"
                            color="primary"
                            className="addMore"
                            startIcon={<AddIcon />}
                            onClick={clickBuyMore(info.type)}
                          >
                            {t('buy_more')}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {selectedType && (
        <CustomDialog
          width="md"
          open={openDialog}
          dialogTitle={t(infoUsage[selectedType].buyMoreTitle)}
          dialogContent={
            <>
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={t('quantity')}
                    disabled={isSubmitting}
                    error={!!errors.quantity?.message}
                    helperText={getErrorText(errors.quantity?.message, t)}
                    {...field}
                  />
                )}
              />
              {selectedType === LicenseType.AdditionalMemberFee && (
                <Typography variant="caption">
                  {t('settings.plan_management.corresponding_number_of_members', buyMoreDescArgs)}
                </Typography>
              )}
            </>
          }
          actions={[
            <Button variant="outlined" disabled={isSubmitting} onClick={handleCloseDialog}>
              {t('cancel')}
            </Button>,
            <Button
              variant="contained"
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmit(handleOpenLicenseStatement)}
            >
              {t('buy_now')}
            </Button>,
          ]}
          onClose={handleCloseDialog}
        />
      )}
      {selectedType && !!quantity && (
        <LicenseStatementDialog
          license={{
            type: selectedType,
            quantity: Number(quantity),
          }}
          open={openLicenseStatement}
          refetchQueries={[GetInfoUsageDocument]}
          onNext={handlePurchaseSuccessful}
          onClose={handleCloseLicenseStatement}
        />
      )}
    </>
  );
};

export default SummaryInformation;
