import {
  ChangeEvent,
  FC,
  ForwardedRef,
  KeyboardEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { CardActions, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { InputProps } from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  jaJP,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
  GridRenderCellParams,
  useGridApiContext,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import { jaJP as jaJPDatePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { ControllerRenderProps, Path, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import AcquisitionToolbar from './AcquisitionToolbar';

import CustomDataGrid from '~/components/CustomDataGrid';
import WrapperTextField from '~/components/WrapperTextField';
import { DATE_TIME_FORMAT } from '~/constants/common';
import { IMyShopFormWrapper, initData, useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { MemberSiteFormEnum } from '~/enum/pages/my-shop';
import { AcquisitionInformationInput, GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { AcquisitionInformationType, ExpireDateMethod, Unit } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

const MAX_NUMBER = 99999999999999999999;

interface RegistrationConditionProps {
  isCreate?: boolean;
  isSubmitting?: boolean;
  onCreate?: (data: IMyShopFormWrapper) => Promise<void>;
}

const useStyles = makeStyles<{ isCreate?: boolean }>()((_, { isCreate }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      minHeight: '52px',
      padding: '0px 16px',
      borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardContent-root': {
      padding: '16px 16px 0',
      display: 'flex',
      '.cardContent': {
        width: '100%',
        '.MuiTypography-h4': {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '32px',
          letterSpacing: '0.25px',
        },
        '.MuiTypography-caption': {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          marginBottom: '16px',
          whiteSpace: 'pre-wrap',
          display: 'inline-block',
        },
        '.wrapperInput': {
          padding: '12px 16px',
          '.MuiTextField-root': {
            '.MuiInputLabel-root': {
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#00000099',
              transform: 'initial',
              letterSpacing: '1.5px',
            },
            '.MuiInput-root .MuiInput-input': {
              padding: 0,
            },
          },
        },
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
  },
  informationTitle: {
    display: 'flex',
  },
  errorText: {
    fontSize: '12px',
    margin: '3px 14px 0',
  },
}));

const schema = yup.object({
  acquisitionInformation: yup.array().of(yup.mixed<AcquisitionInformationInput>().required()).required(),
  expireMethod: yup.mixed<ExpireDateMethod>().oneOf(Object.values(ExpireDateMethod)).required(),
  expireDate: yup.mixed<Dayjs>().when('expireMethod', {
    is: ExpireDateMethod.SPECIFY_DATE,
    then: (schema) => schema.required(),
  }),
  expireValue: yup
    .number()
    .typeError('form_validation.this_field_cannot_be_empty')
    .min(1)
    .max(MAX_NUMBER)
    .test({
      message: 'form_validation.can_only_contain_integer',
      test: (value) => /^[0-9]*$/.test(value?.toString() || ''),
    })
    .when('expireMethod', {
      is: ExpireDateMethod.SPECIFY_HOW_LONG,
      then: (schema) => schema.required(),
    }),
  expireUnit: yup
    .mixed<Unit>()
    .oneOf(Object.values(Unit))
    .when('expireMethod', {
      is: ExpireDateMethod.SPECIFY_HOW_LONG,
      then: (schema) => schema.required(),
    }),
});

export interface FormRegistrationConditionValues extends yup.InferType<typeof schema> {}

export const initInfoType = (t: TFunction) => ({
  [AcquisitionInformationType.NAME]: {
    value: AcquisitionInformationType.NAME,
    label: 'Name',
    type: t('name.person'),
  },
  [AcquisitionInformationType.EMAIL]: {
    value: AcquisitionInformationType.EMAIL,
    label: 'E-mail address',
    type: t('email_address'),
  },
  [AcquisitionInformationType.ADDRESS]: {
    value: AcquisitionInformationType.ADDRESS,
    label: 'Address',
    type: t('address'),
  },
});

const initExpireMethods = (t: TFunction) => ({
  [ExpireDateMethod.SPECIFY_DATE]: {
    value: ExpireDateMethod.SPECIFY_DATE,
    label: t('member_site.specify_date'),
    description: t('member_site.specify_date_description'),
  },
  [ExpireDateMethod.SPECIFY_HOW_LONG]: {
    value: ExpireDateMethod.SPECIFY_HOW_LONG,
    label: t('member_site.specify_how_long'),
    description: t('member_site.specify_how_long_description'),
  },
  [ExpireDateMethod.NOT_EXPIRE]: {
    value: ExpireDateMethod.NOT_EXPIRE,
    label: t('member_site.not_expire'),
    description: t('member_site.not_expire_description'),
  },
});

const initUnits = (t: TFunction) => ({
  [Unit.DAY]: {
    value: Unit.DAY,
    label: t('day'),
  },
  [Unit.MONTH]: {
    value: Unit.MONTH,
    label: t('month'),
  },
  [Unit.YEAR]: {
    value: Unit.YEAR,
    label: t('year'),
  },
});

const Empty = () => {
  return <></>;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '4px !important',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const TextEditInputCell = forwardRef(
  (props: { extendProps: GridRenderCellParams }, ref: ForwardedRef<HTMLDivElement>) => {
    const { extendProps, ...others } = props;
    const { id, value, field } = extendProps;
    const apiRef = useGridApiContext();

    const handleChange: InputProps['onChange'] = async (event) => {
      const newValue = event.target.value;
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <TextField
        fullWidth
        ref={ref}
        {...others}
        value={value !== undefined && value !== null ? `${value}` : ''}
        onChange={handleChange}
      />
    );
  }
);

const renderSelectEditInputCell = (t: TFunction) => (params: GridRenderEditCellParams) => {
  const { error } = params;
  return (
    <StyledTooltip open={!!error} title={t('form_validation.this_field_cannot_be_empty')}>
      <TextEditInputCell extendProps={params} />
    </StyledTooltip>
  );
};

const RegistrationCondition: FC<RegistrationConditionProps> = ({ isCreate, isSubmitting, onCreate }) => {
  const { editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handlePreviousStep } = useMyShopFormWrapper();

  const [updating, setUpdating] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);

  const isEditing = editingAt === MemberSiteFormEnum.REGISTRATION;

  const { id: shopId } = useParams();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles({ isCreate });

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const defaultValues = useMemo(
    () => {
      const nftActivationSettings = formData?.nftActivationSettings;
      const acquisitionInformation = nftActivationSettings?.acquisitionInformation;
      return {
        acquisitionInformation,
        expireDate: nftActivationSettings?.expireDate,
        expireUnit: nftActivationSettings?.expireUnit,
        expireValue: nftActivationSettings?.expireValue,
        expireMethod: nftActivationSettings?.expireMethod,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData?.nftActivationSettings]
  );

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormRegistrationConditionValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleChangeToEdit = () => {
    handleEditingAt(MemberSiteFormEnum.REGISTRATION);
  };
  const handleCancel = () => {
    if (isCreate) {
      handlePreviousStep();
    } else {
      reset(defaultValues);
      setRowModesModel((prevState) => {
        const newRowModesModel: GridRowModesModel = {};
        Object.keys(prevState).forEach((idRow) => {
          newRowModesModel[idRow] = { mode: GridRowModes.View };
        });
        return newRowModesModel;
      });
      handleEditingAt(undefined);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: 'acquisitionInformation',
  });

  const [expireMethod, acquisitionInformation] = useWatch({
    control,
    name: ['expireMethod', 'acquisitionInformation'],
  });

  const rowTypeOptions = useCallback(
    (currentRow?: AcquisitionInformationInput) => {
      if (!currentRow) {
        return [];
      }

      const currentInfoTypes = acquisitionInformation?.reduce((result, info) => {
        if (currentRow.id !== info?.id) {
          result.push(info?.type!);
        }
        return result;
      }, [] as string[]);

      const newInfoTypes = Object.values(initInfoType(t)).reduce((result, info) => {
        if (!currentInfoTypes?.includes(info.value)) {
          result.push({ value: info.value, label: info.type });
        }
        return result;
      }, [] as Array<{ value: string; label: string }>);

      return newInfoTypes;
    },
    [t, acquisitionInformation]
  );

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const rowIdx = fields.findIndex((row) => row.id === id);
    remove(rowIdx);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowEditStart = (_: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: AcquisitionInformationInput & Partial<Record<'key', string>>) => {
    const rowIdx = fields.findIndex((row) => newRow.id === row.id);
    delete newRow.key;
    setValue(`acquisitionInformation.${rowIdx}`, newRow);
    return newRow;
  };

  const blockInvalidChar: KeyboardEventHandler<HTMLDivElement> | undefined = (e) =>
    ['e', 'E', '+', '-', ',', '.'].includes(e.key) && e.preventDefault();

  const onSubmit = async (data: FormRegistrationConditionValues) => {
    const isAcquisitionEditing = Object.values(rowModesModel).some((row) => row.mode === 'edit');
    setUpdating(true);
    if (isAcquisitionEditing) {
      enqueueSnackbar(
        t('form_validation.section_not_been_saved', { section: t('member_site.acquisition_information') }),
        {
          variant: 'error',
        }
      );
      setUpdating(false);
      return;
    }
    const finalizeData = {
      ...formData,
      nftActivationSettings: data,
    };
    setFormData(finalizeData);
    if (isCreate) {
      if (onCreate) {
        onCreate(finalizeData);
      }
    } else {
      try {
        await updateMyShop({
          variables: {
            input: {
              uuid: shopId ?? '',
              nftActivationSettings: {
                ...data,
                expireValue: data.expireValue?.toString(),
              },
            },
          },
        });
        handleEditingAt(undefined);
        setUpdating(false);
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  };

  const expireMethodOptions = useMemo(
    () =>
      Object.values(initExpireMethods(t)).map((method) => (
        <MenuItem key={method.value} value={method.value}>
          {method.label}
        </MenuItem>
      )),
    [t]
  );

  const unitOptions = useMemo(
    () =>
      Object.values(initUnits(t)).map((unit) => (
        <MenuItem key={unit.value} value={unit.value}>
          {unit.label}
        </MenuItem>
      )),
    [t]
  );

  const addsSpecificOptions = useCallback(() => {
    switch (expireMethod) {
      case ExpireDateMethod.SPECIFY_DATE:
        return (
          <WrapperTextField
            control={control}
            name="expireDate"
            isEditing={isCreate || isEditing}
            label={t('member_site.expire_date')}
            formatValue={(field) => {
              return dayjs(field.value as string).format(t('date_format'));
            }}
            render={(field) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language}
                localeText={jaJPDatePicker.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  onError={(reason) => {
                    if (reason === 'invalidDate') {
                      setInvalidDate(true);
                    } else {
                      setInvalidDate(false);
                    }
                  }}
                  sx={(theme) => ({
                    width: '100%',
                    [theme.breakpoints.up('md')]: {
                      width: '50%',
                    },
                  })}
                  format={DATE_TIME_FORMAT}
                  label={t('member_site.expire_date')}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        );
      case ExpireDateMethod.SPECIFY_HOW_LONG:
        return (
          <Box display="flex" gap="16px">
            <WrapperTextField
              label={t('number')}
              control={control}
              name="expireValue"
              isEditing={isCreate || isEditing}
              render={(field) => (
                <TextField
                  required
                  type="number"
                  sx={{ flex: 1 }}
                  variant="outlined"
                  placeholder={t('number')}
                  error={!!errors.expireValue?.message}
                  helperText={getErrorText(errors.expireValue?.message, t, 'number')}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: MAX_NUMBER }}
                  onKeyDown={blockInvalidChar}
                  {...field}
                />
              )}
            />
            <WrapperTextField
              label={t('unit')}
              name="expireUnit"
              control={control}
              isEditing={isCreate || isEditing}
              formatValue={(field) => initUnits(t)[field.value as Unit].label}
              render={(field) => (
                <TextField
                  select
                  required
                  sx={{ flex: 1 }}
                  label={t('unit')}
                  error={!!errors.expireUnit?.message}
                  helperText={t(errors.expireUnit?.message as any)}
                  {...field}
                >
                  {unitOptions}
                </TextField>
              )}
            />
          </Box>
        );
      case ExpireDateMethod.NOT_EXPIRE:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, control, expireMethod, t, isEditing]);

  const columns: GridColDef<AcquisitionInformationInput>[] = useMemo(
    () => {
      const columnsSize = localStorage.getItem('columnsSize') || '{}';
      return [
        ...(isCreate || isEditing
          ? [
              {
                width: 80,
                headerName: '',
                type: 'actions',
                sortable: false,
                resizable: false,
                disableReorder: true,
                field: t('information'),
                getActions: (params: GridRowParams<AcquisitionInformationInput>) => {
                  const { id, row } = params;
                  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                  if (isInEditMode) {
                    return [
                      <GridActionsCellItem icon={<SaveIcon />} label={t('save')} onClick={handleSaveClick(id)} />,
                      <GridActionsCellItem
                        icon={<CancelIcon />}
                        label={t('cancel')}
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                      />,
                    ];
                  }

                  return [
                    <GridActionsCellItem
                      icon={<DeleteOutlineOutlinedIcon />}
                      label="Delete"
                      onClick={handleDeleteClick(id)}
                    />,
                    <GridActionsCellItem
                      icon={<ModeEditOutlineOutlinedIcon />}
                      label={t('edit')}
                      className="textPrimary"
                      onClick={handleEditClick(id)}
                    />,
                  ];
                },
              },
            ]
          : []),
        {
          type: 'string',
          sortable: false,
          field: 'displayName',
          headerName: t('display_name'),
          width: JSON.parse(columnsSize).displayName || 150,
          renderEditCell: renderSelectEditInputCell(t),
          preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = params.props.value.length === 0;
            return { ...params.props, error: hasError };
          },
          editable: true,
        },
        {
          field: 'type',
          editable: true,
          sortable: false,
          type: 'singleSelect',
          headerName: t('type'),
          width: JSON.parse(columnsSize).type || 150,
          valueOptions: ({ row }) => rowTypeOptions(row),
        },
        {
          editable: true,
          sortable: false,
          type: 'boolean',
          field: 'required',
          headerName: t('required_field'),
          width: JSON.parse(columnsSize).required || 150,
          renderCell: ({ row }) => <Checkbox disabled checked={!!row.required} />,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing, rowModesModel, t, rowTypeOptions]
  );

  const handleChangeExpireMethod =
    (field: ControllerRenderProps<FormRegistrationConditionValues, Path<FormRegistrationConditionValues>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);
      if (invalidDate) {
        setInvalidDate(false);
      }
      reset({
        ...initData.nftActivationSettings,
        acquisitionInformation,
        expireMethod: event.target.value as ExpireDateMethod,
      });
    };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={t('member_site.registration_condition')}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent">
            <Typography variant="caption">{t('member_site.nft_activation_settings_description')}</Typography>
            <Box className={classes.informationTitle}>
              <Typography variant="h4">{t('member_site.acquisition_information')}</Typography>
            </Box>
            <Box marginBottom="16px">
              <CustomDataGrid
                autoHeight
                rows={fields}
                rowHeight={59}
                editMode="row"
                columns={columns}
                disableColumnMenu
                disableRowSelectionOnClick
                rowModesModel={rowModesModel}
                onRowEditStop={handleRowEditStop}
                onRowEditStart={handleRowEditStart}
                processRowUpdate={processRowUpdate}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                slots={{
                  toolbar: isCreate || isEditing ? AcquisitionToolbar : undefined,
                  pagination: Empty,
                }}
                slotProps={{
                  toolbar: { control, append, setRowModesModel },
                }}
                localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
              />
            </Box>
            <Typography variant="h4">{t('member_site.expire_date')}</Typography>
            <Typography variant="caption">{t('member_site.expire_date_description')}</Typography>
            <WrapperTextField
              isEditing={isCreate || isEditing}
              label={t('method')}
              name="expireMethod"
              control={control}
              formatValue={(field) => initExpireMethods(t)[field.value as ExpireDateMethod].label}
              render={(field) => (
                <TextField
                  select
                  required
                  fullWidth
                  label={t('method')}
                  error={!!errors.expireMethod?.message}
                  helperText={t(errors.expireMethod?.message as any)}
                  {...field}
                  onChange={handleChangeExpireMethod(field)}
                >
                  {expireMethodOptions}
                </TextField>
              )}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" marginTop="8px">
                {initExpireMethods(t)[expireMethod].description}
              </Typography>
              {addsSpecificOptions()}
            </Box>
            {invalidDate && (
              <Typography sx={{ color: '#DF0303', fontSize: '12px', fontWeight: 400 }}>
                {t('form_validation.this_field_cannot_be_empty')}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting || updating}>
                {t(isCreate ? 'back' : 'cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={
                  (!isCreate &&
                    JSON.stringify(defaultValues.acquisitionInformation) === JSON.stringify(acquisitionInformation) &&
                    !isDirty) ||
                  isSubmitting ||
                  updating ||
                  invalidDate
                }
                onClick={handleSubmit(onSubmit)}
                endIcon={(isSubmitting || updating) && <CircularProgress size={20} color="inherit" />}
              >
                {t(isCreate ? 'create' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default RegistrationCondition;
