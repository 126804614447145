import { Erc721Metadata } from '@gu-corp/react-ipfs-media';
import moment from 'moment';

import { fetchWithTimeout } from './common';
import { NFTToken } from './fetch-nfts';

import { env } from '~/env';
import { TokenQuery } from '~/graphql/subgraph/types';

export const getNFTMetadata = async (
  collectionUuid: string,
  tokenInfo: TokenQuery['token'],
  ipfsGateways: string[] | undefined
) => {
  const key = `${collectionUuid}-${parseInt(tokenInfo?.tokenID, 10) - 1}`;
  let metadata: NFTToken = {
    collectionUuid: collectionUuid!,
    createdAt: moment.unix(tokenInfo?.mintTime).toString(),
    description: '',
    metadataContent: {
      image: '',
      name: '',
      animation_url: '',
      description: '',
    },
    metadataUrl: tokenInfo?.tokenURI ?? '',
    name: '',
    ownerAddress: tokenInfo?.owner.id ?? '',
    tokenId: tokenInfo?.tokenID,
    uuid: key,
  };
  let cachedToken: NFTToken | undefined;
  try {
    cachedToken = JSON.parse(localStorage.getItem(key) || '');
  } catch (err: any) {}
  if (cachedToken) {
    metadata.metadataContent = {
      animation_url: '',
      image: cachedToken.metadataContent.image,
      name: cachedToken.metadataContent?.name || '',
      description: cachedToken.metadataContent?.description || '',
    };
    cachedToken.ownerAddress = tokenInfo?.owner.id ?? cachedToken.ownerAddress;
    localStorage.setItem(key, JSON.stringify(cachedToken));
  } else {
    let erc721Metadata: Erc721Metadata | undefined | null;
    const tokenUri = tokenInfo?.tokenURI;
    if (!!tokenUri) {
      const isIpfs = tokenUri.includes('ipfs://');
      if (!isIpfs) {
        erc721Metadata = await fetchMetadataFromApiServer(tokenUri);
      } else {
        erc721Metadata = await fetchMetadataFromIpfs(tokenUri, ipfsGateways);
      }
    }
    metadata.metadataContent = {
      animation_url: '',
      image: erc721Metadata?.image ? erc721Metadata.image : '/images/gu-logo.svg',
      name: erc721Metadata?.name || '',
      description: erc721Metadata?.description || '',
    };
    localStorage.setItem(key, JSON.stringify(metadata));
  }
  return metadata;
};

const fetchMetadataFromApiServer = async (tokenUri: string) => {
  const res = await fetch(env.REACT_APP_API_MEDIA.replace('media', 'metadata'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tokenUri: tokenUri || '',
    }),
  });
  const resJson = await res.json();
  return resJson;
};

const fetchMetadataFromIpfs = async (
  tokenUri: string,
  ipfsGateways?: string[],
  currentIndex = 0
): Promise<Erc721Metadata> => {
  if (!tokenUri || tokenUri.includes('undefined') || !ipfsGateways?.length) {
    throw new Error();
  }
  const length = ipfsGateways.length;
  if (currentIndex >= length) {
    throw new Error();
  }
  try {
    const currentGateway = ipfsGateways[currentIndex];
    const url = tokenUri.replace('ipfs://', currentGateway) || '';
    const data = await fetchWithTimeout(url, 10000);
    const metadataRes: Erc721Metadata = await data?.json();
    return metadataRes;
  } catch (err) {
    return await fetchMetadataFromIpfs(tokenUri, ipfsGateways, currentIndex + 1);
  }
};
