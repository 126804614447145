import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider } from 'ethers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import WrapperExistingNFTList from './Minted/WrapperExistingNFTList';
import WrapperImageNFTList from './MintOnPurchase/WrapperImageNFTList';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import LinkButton from '~/components/link-button';
import LoaderCenter from '~/components/loader-center';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  Collection,
  GetMyShopCollectionByCollectionIdQuery,
  GetMyShopDocument,
  useGetMyShopCollectionByCollectionIdQuery,
  useUnAttachMyShopCollectionsMutation,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_ } from '~/graphql/subgraph/types';
import { useNotify } from '~/hooks/useNotify';
import { GRANT_PERMISSION, MEMBER_SITE_NFT_STATUS, NFT_SHOP_TYPE, ShopDetailTab } from '~/types/my-shop';
import { ERROR_CODE } from '~/utils/not-found';

export interface NFTStatus {
  [key: string]: MEMBER_SITE_NFT_STATUS;
}

export interface ShopCollectionDetailContextValue {
  loading: boolean;
  data: GetMyShopCollectionByCollectionIdQuery[keyof GetMyShopCollectionByCollectionIdQuery];
}

const ShopCollectionDetailContext = createContext<ShopCollectionDetailContextValue>({} as any);
export const useShopCollectionDetail = () => useContext(ShopCollectionDetailContext);

export interface ICollectionInfo extends Collection {
  status?: GRANT_PERMISSION;
  myShopCollectionName?: string | null;
}

const ShopCollectionDetail = () => {
  const { data: myShop } = useShopDetail();
  const { supportedNetworks } = useSupportedNetworks();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const { id: shopId, collectionId } = useParams();

  const isPreMint = myShop?.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;

  const [openWalletConnector, setOpenWalletConnector] = useState(false);
  const [collectionInfo, setCollectionInfo] = useState<ICollectionInfo>();
  const [loadingCollectionInfo, setLoadingCollectionInfo] = useState<boolean>(true);
  const [openRemoveCollectionDialog, setOpenRemoveCollectionDialog] = useState(false);

  const [unattachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const { data: myShopCollectionRes, loading: loadingMyShopCollection } = useGetMyShopCollectionByCollectionIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        collectionUuid: collectionId!,
        shopUuid: shopId!,
      },
    },
    onError: (err) => {
      if (Object.values(err)?.[0]?.[0]?.extensions?.code === ERROR_CODE.NOT_FOUND) {
        navigate(AppRouteEnum.MyShop);
      }
    },
  });

  const myShopCollectionData = useMemo(
    () => myShopCollectionRes?.getMyShopCollectionByCollectionId,
    [myShopCollectionRes?.getMyShopCollectionByCollectionId]
  );

  // Handle Wallet Connector
  const handleCloseWalletConnector = () => {
    setOpenWalletConnector(false);
  };

  // Handle Remove Collection Dialog
  const handleOpenRemoveCollectionDialog = () => {
    setOpenRemoveCollectionDialog(true);
  };
  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveCollectionDialog(false);
  }, []);

  const onUnattachCollection = useCallback(async () => {
    try {
      await unattachCollectionShop({
        variables: {
          input: {
            shopUuid: shopId || '',
            collectionUuids: [collectionId || ''],
          },
        },
      });
      navigate(AppRouteEnum.ShopDetail.replace(/:id/g, shopId || ''));
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }, [shopId, collectionId, navigate, enqueueSnackbar, unattachCollectionShop]);

  useEffect(() => {
    if (!loadingMyShopCollection && myShopCollectionData) {
      (async () => {
        try {
          setLoadingCollectionInfo(true);
          const collectionInfoRes = myShopCollectionData.collection!;
          const provider = new JsonRpcProvider(supportedNetworks?.[collectionInfoRes.network]?.rpcUrl);
          const contractWithoutSigner = ERC721G__factory.connect(collectionInfoRes.contractAddress, provider as any);
          const owner = await contractWithoutSigner.owner();
          setCollectionInfo({
            ...collectionInfoRes,
            ownerAddress: owner,
            myShopCollectionName: myShopCollectionData.name,
          });
          setLoadingCollectionInfo(false);
        } catch (err: any) {
          showError(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMyShopCollection]);

  const renderContent = useMemo(
    () =>
      isPreMint ? (
        <WrapperImageNFTList
          collectionInfo={collectionInfo}
          onOpenRemoveCollectionDialog={handleOpenRemoveCollectionDialog}
        />
      ) : (
        <WrapperExistingNFTList
          collectionInfo={collectionInfo}
          onOpenRemoveCollectionDialog={handleOpenRemoveCollectionDialog}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPreMint, collectionInfo]
  );

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.MyShop}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('my_shop.shops')}
        </Link>
        <Link
          component={RouterLink}
          to={AppRouteEnum.ShopDetail.replace(/:id/g, shopId || '')}
          color="text.primary"
          underline="hover"
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {myShop?.siteSetting?.title ?? ''}
        </Link>
        <Typography
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.secondary"
        >
          {collectionInfo?.myShopCollectionName || collectionInfo?.name || '...'}
        </Typography>
      </Breadcrumbs>
      <LinkButton
        to={`${AppRouteEnum.ShopDetail.replace(/:id/g, shopId || '')}?tab=${ShopDetailTab.COLLECTION}`}
        startIcon={<ArrowBackIcon />}
      >
        {t('back')}
      </LinkButton>
      {loadingCollectionInfo ? (
        <LoaderCenter />
      ) : (
        <>
          <ShopCollectionDetailContext.Provider
            value={{
              data: myShopCollectionData!,
              loading: loadingMyShopCollection,
            }}
          >
            <ConfirmationDialog
              open={openRemoveCollectionDialog}
              title={t('my_shop.message.remove_shop_message')}
              content={t('my_shop.message.confirm_remove_shop')}
              confirmTitle={t('remove')}
              onClose={onCloseRemoveMyShopDialog}
              onConfirm={onUnattachCollection}
            />
            {renderContent}
            <WalletConnectorDialog open={openWalletConnector} onClose={handleCloseWalletConnector} />
          </ShopCollectionDetailContext.Provider>
        </>
      )}
    </>
  );
};

export default ShopCollectionDetail;
