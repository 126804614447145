import { FC, useContext, useEffect, useRef, useState } from 'react';

import {
  useSigner,
  useSwitchNetwork,
  useAccount as useWalletAccount,
  WalletRequired,
} from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { formatEther, JsonRpcProvider, parseEther } from 'ethers';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';
import InvoiceDialog, { IInvoice, InvoiceCurrency } from '../invoice-dialog';

import { NumberStringTextField } from '~/components/NumberStringTextField';
import { CURRENCY_ICONS, JOC_POINT_PRICE } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { GetTransactionsHistoryDocument } from '~/graphql/block-explorer/types';
import {
  Currency,
  useBuyPointMutation,
  useEstimatePointMutation,
  useGetSystemSettingsQuery,
  useWithdrawMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';

interface IDepositDialog {
  open: boolean;
  balance: number;
  isWithdraw: boolean;
  refetchBalance: () => void;
  onClose: (submitted?: boolean) => void;
}

const schema = yup.object({
  isWithdraw: yup.boolean(),
  byCreditCard: yup.boolean(),
  remainingPoints: yup.number(),
  amount: yup
    .string()
    .test(
      'lessMaxBalance',
      'form_validation.number_max',
      (value, context) => context.parent.isWithdraw || !value || parseFloat(value) <= context.parent.remainingPoints
    )
    .when('byCreditCard', {
      is: true,
      then: (schema) => schema.test('greaterThan5', 'form_validation.number_min', (value) => Number(value) >= 5),
    })
    .required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const DepositDialog: FC<IDepositDialog> = ({ open, balance, isWithdraw, onClose, refetchBalance }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const signer = useSigner();
  const { t } = useTranslation();
  const { account } = useWalletAccount();
  const { show } = usePaymentMethodRequired();
  const { switchNetwork } = useSwitchNetwork();
  const { selectedOrganization } = useAccount();
  const { showErrorByKey, showSuccess } = useNotify();

  const [nftsPerPoint, setNFTsPerPoint] = useState(0);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoice>();

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const latestNetwork = Object.values(supportedNetworks)?.[0];
  const organizationWallet = selectedOrganization.masterWalletAddress;

  const [estimatePoint] = useEstimatePointMutation();
  const [buyPoint] = useBuyPointMutation({
    refetchQueries: [GetTransactionsHistoryDocument],
  });
  const [withdraw] = useWithdrawMutation({
    refetchQueries: [GetTransactionsHistoryDocument],
  });

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      amount: '',
      remainingPoints: 0,
    },
    resolver: yupResolver(schema),
  });

  const { data: systemSettingsRes } = useGetSystemSettingsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [byCreditCard, remainingPoints] = useWatch({ control, name: ['byCreditCard', 'remainingPoints'] });

  const validationBalance = async (data: FormValues) => {
    if (!account || !organizationWallet) {
      return 'my_shop.message.error';
    }
    const provider = new JsonRpcProvider(latestNetwork?.rpcUrl);
    let balanceBigNumber;
    if (isWithdraw) {
      balanceBigNumber = parseEther(balance.toString());
    } else {
      balanceBigNumber = await provider.getBalance(account);
    }
    const amountEth = parseEther(data.amount);
    return amountEth <= balanceBigNumber
      ? ''
      : isWithdraw
      ? 'toast_message.insufficient_funds_to_refund'
      : 'toast_message.not_enough_fund_to_purchase';
  };

  const validation = async (data: FormValues) => {
    await switchNetwork(Number(latestNetwork.chainId));
    await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
    if (!refSigner.current || !organizationWallet) {
      return false;
    }
    const errorMessage = await validationBalance(data);
    if (!!errorMessage) {
      showErrorByKey(errorMessage);
      return false;
    }
    return true;
  };

  const onWithdraw = async (data: FormValues) => {
    const result = await validation(data);
    if (!result) return;
    const withdrawRes = await withdraw({
      variables: {
        input: {
          amount: data.amount,
          walletAddress: account,
          network: latestNetwork.chainId,
        },
      },
    });
    refetchBalance();
    if (!withdrawRes?.data || !!withdrawRes.errors) {
      showErrorByKey('my_shop.message.error');
      return;
    }
    reset();
    onClose(true);
    showSuccess('toast_message.refunded_successfully');
  };

  const onBuyPoints = async () => {
    try {
      const amount = invoiceDetail?.items?.[0].quantity;
      if (!amount) return false;
      if (byCreditCard) {
        const notRegistered = await show({
          description: 'settings.points.require_payment_method_desc',
        });
        if (notRegistered) {
          return false;
        } else {
          await buyPoint({
            variables: {
              input: {
                point: amount,
                currency: Currency.Usd,
                chainId: latestNetwork.chainId,
              },
            },
          });
        }
      } else {
        const point = parseEther(amount.toString());
        const request = await refSigner.current.sendTransaction({
          value: point,
          to: organizationWallet,
        });
        await request.wait();
      }
      refetchBalance();
      reset();
      onClose(true);
      return true;
    } catch (err: any) {
      throw err;
    }
  };

  // Handle Invoice Dialog
  const handleOpenInvoice = async (data: FormValues) => {
    try {
      let newInvoiceDetail: IInvoice;
      if (data.byCreditCard) {
        const estimateRes = await estimatePoint({
          variables: {
            input: {
              currency: Currency.Usd,
              point: parseFloat(data.amount),
              chainId: latestNetwork.chainId,
            },
          },
        });
        const estimateFee = estimateRes.data?.estimatePoint;
        newInvoiceDetail = {
          items: [
            {
              price: estimateFee?.price || 0,
              total: estimateFee?.total || 0,
              quantity: estimateFee?.point || 0,
              name: 'settings.points.joc_points',
            },
          ],
          tax: 0,
          total: estimateFee?.total || 0,
          currency: InvoiceCurrency.USD,
        };
      } else {
        const result = await validation(data);
        if (!result) return;
        const amount = parseFloat(data.amount);
        newInvoiceDetail = {
          items: [
            {
              price: 1,
              total: amount,
              quantity: amount,
              name: 'settings.points.joc_points',
            },
          ],
          total: amount,
          currency: InvoiceCurrency.JOC,
        };
      }
      setInvoiceDetail(newInvoiceDetail);
      setOpenInvoice(true);
    } catch (err: any) {
      console.log(err);
    }
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleBuyPointsByCreditCard = () => {
    setValue('byCreditCard', true);
    handleSubmit(handleOpenInvoice)();
  };

  const handleBuyPointsByJOCTokens = async () => {
    setValue('byCreditCard', false);
    handleSubmit(handleOpenInvoice)();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (open) {
      (async () => {
        const provider = new JsonRpcProvider(latestNetwork.rpcUrl);
        const { gasPrice } = await provider.getFeeData();
        if (gasPrice) {
          const gasLimit = BigInt(21000);
          const gasFee = formatEther(gasLimit * gasPrice);
          const maxNFTs = Math.floor(1 / parseFloat(gasFee));
          setNFTsPerPoint(maxNFTs);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const maximumPoints = systemSettingsRes?.getSystemSettings.maximumPoint;
    if (!!open && typeof maximumPoints === 'number' && typeof balance === 'number') {
      const remainingPoints = Math.floor(maximumPoints - balance);
      setValue('remainingPoints', remainingPoints > 0 ? remainingPoints : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, balance, systemSettingsRes?.getSystemSettings.maximumPoint]);

  useEffect(() => {
    setValue('isWithdraw', isWithdraw);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWithdraw]);

  return (
    <>
      <CustomDialog
        width="md"
        open={open}
        onClose={handleClose}
        dialogTitle={t(isWithdraw ? 'settings.refund' : 'settings.points.buy_points')}
        dialogContent={
          <>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <NumberStringTextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  disabled={isSubmitting}
                  label={t('settings.amount')}
                  error={!!errors.amount?.message}
                  helperText={
                    <Trans
                      i18nKey={errors.amount?.message}
                      values={{
                        min: '5',
                        label: t('settings.amount'),
                        max: remainingPoints?.toLocaleString() || 0,
                      }}
                    />
                  }
                  {...field}
                />
              )}
            />
            {!isWithdraw && (
              <Typography variant="caption">{`${t('settings.points.one_joc_point')} = ${
                CURRENCY_ICONS[Currency.Usd]
              }${JOC_POINT_PRICE} ≈ ${t('settings.points.nft_minting_times', {
                amount: nftsPerPoint?.toLocaleString() || 0,
              })}`}</Typography>
            )}
          </>
        }
        actions={
          isWithdraw
            ? [
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                  onClick={handleSubmit(onWithdraw)}
                >
                  {t('settings.refund')}
                </Button>,
              ]
            : [
                <WalletRequired key={0}>
                  <Button
                    variant="outlined"
                    disabled={isSubmitting}
                    endIcon={isSubmitting && !byCreditCard && <CircularProgress size={20} color="inherit" />}
                    onClick={handleBuyPointsByJOCTokens}
                  >
                    {t('settings.points.joc_tokens')}
                  </Button>
                </WalletRequired>,
                <Button
                  key={1}
                  variant="contained"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && byCreditCard && <CircularProgress size={20} color="inherit" />}
                  onClick={handleBuyPointsByCreditCard}
                >
                  {t('settings.points.credit_card')}
                </Button>,
              ]
        }
      />
      <InvoiceDialog open={openInvoice} data={invoiceDetail} onClose={handleCloseInvoice} onSubmit={onBuyPoints} />
    </>
  );
};

export default DepositDialog;
