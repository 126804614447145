import { useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import SmartContract from '../SmartContract';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import ImageUploadInput from '~/components/image-upload-input';
import LoaderCenter from '~/components/loader-center';
import PermissionsView from '~/components/PermissionsView';
import { WrapperTextFieldNoEdit } from '~/components/WrapperTextField';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { API_MEDIA } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { GetCollectionDocument, useUpdateCollectionMutation, useUploadFileMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { ICollectionInfo } from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import { SalesMethodEnum } from '~/types/my-shop';

interface CollectionInfoBoxProps {
  isMyShopScreen?: boolean;
  salesMethod?: SalesMethodEnum;
  collectionData: ICollectionInfo | undefined;
  onOpenRemoveCollectionDialog: () => void;
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingBottom: '24px',
    '.img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '.MuiCardContent-root': {
      gap: '16px',
      display: 'flex',
      '&:last-child': {
        paddingBottom: '16px!important',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
      '.cardContent': {
        '&__left': {
          gap: '8px',
          width: '257px',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&-networkChain': {
            display: 'flex',
            alignItems: 'center',
            img: {
              width: '24px',
              marginRight: '8px',
            },
            '.MuiTypography-caption': {
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
            },
          },
          '&-wrapperSiteImage': {
            '&--siteImage': {
              height: '180px',
              marginTop: '16px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                height: '228px',
              },
            },
          },
        },
        '&__right': {
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          '&-wrapperInput': {
            '.MuiTextField-root': {
              '.MuiInputAdornment-root': {
                '.MuiTypography-root': {
                  fontSize: '16px',
                },
                '.MuiTypography-caption': {
                  '&.in-desktop': {
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                      display: 'inline',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: 'flex-end',
    },
  },
}));

const CollectionDetailInfo: React.FC<CollectionInfoBoxProps> = (props) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { collectionData, onOpenRemoveCollectionDialog } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const currentNetwork = collectionData ? supportedNetworks?.[collectionData.network] : undefined;

  const { showError, showSuccess } = useNotify();
  const { control, setValue } = useForm<any>({
    defaultValues: {},
    mode: 'onChange',
  });

  const [uploadFile, { loading: uploading }] = useUploadFileMutation();
  const [updateCollection, { loading: updating }] = useUpdateCollectionMutation({
    refetchQueries: [GetCollectionDocument],
  });

  const onChangeImage = async (data: FileList) => {
    if (!collectionData?.uuid) {
      return;
    }

    try {
      setValue('images', data);
      const uploadedImagesList = await uploadFile({
        variables: { files: data },
      });

      if (uploadedImagesList?.data?.uploadFile) {
        await updateCollection({
          variables: {
            input: {
              uuid: collectionData?.uuid,
              images: uploadedImagesList?.data?.uploadFile,
            },
          },
        });
        showSuccess('toast_message.updated_successfully');
      } else {
        setValue('images', null);
      }
    } catch (error) {
      showError(error);
      setValue('images', null);
    }
  };

  return (
    <Box>
      {!(collectionData && currentNetwork) ? (
        <LoaderCenter />
      ) : (
        <Box className={classes.wrapper}>
          <CustomCardTable
            cardTitle={t('collection_basic_information')}
            headerAction={
              <PermissionsView roles={SCREEN_PERMISSION.COLLECTIONS.DELETE}>
                <HeaderAction menus={[{ title: t('delete'), onClick: onOpenRemoveCollectionDialog }]} />
              </PermissionsView>
            }
            cardContent={
              <>
                <Box className="cardContent__left">
                  <Box className="cardContent__left-wrapperSiteImage">
                    <Box className="cardContent__left-wrapperSiteImage--siteImage">
                      <ImageUploadInput<any>
                        required
                        type="image"
                        name="images"
                        height="100%"
                        control={control}
                        label={t('image')}
                        defaultImageUrlName="images"
                        loading={uploading || updating}
                        disabled={uploading || updating}
                        defaultImagePath={API_MEDIA + collectionData?.images?.[0]}
                        onChange={(data) => onChangeImage(data)}
                      />
                    </Box>
                  </Box>
                  <Box className="cardContent__left-networkChain">
                    <img src={currentNetwork.icon || ''} alt="Network Chain" />
                    <Typography variant="caption">{currentNetwork.name}</Typography>
                  </Box>
                </Box>
                <Box className="cardContent__right">
                  <Box className="cardContent__right-wrapperInput">
                    <WrapperTextFieldNoEdit label={t('contract_address')} value={collectionData.contractAddress} />
                  </Box>
                  <Box className="cardContent__right-wrapperInput">
                    <WrapperTextFieldNoEdit
                      label={t('my_shop.collection_name')}
                      value={collectionData?.name || '...'}
                    />
                  </Box>
                  <Box className="cardContent__right-wrapperInput">
                    <WrapperTextFieldNoEdit label={t('symbol')} value={collectionData?.symbol} />
                  </Box>
                  <Box className="cardContent__right-wrapperInput">
                    <WrapperTextFieldNoEdit label={t('owner_address')} value={collectionData.ownerAddress || '-'} />
                  </Box>
                  <Box className="cardContent__right-wrapperInput">
                    <WrapperTextFieldNoEdit
                      label={t('created_date')}
                      value={
                        collectionData.createdAt
                          ? moment(collectionData.createdAt).format(t('date_time_format')).replace(/-/g, '')
                          : '-'
                      }
                    />
                  </Box>
                </Box>
              </>
            }
          />
        </Box>
      )}
      {!collectionData?.isErc721 && <SmartContract collection={collectionData} />}
    </Box>
  );
};

export default CollectionDetailInfo;
